import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Wrapper, Label, LeftDiv, Spacer, TextFieldLabel, MessageLabel, TextFieldSmall, Th, Td, TextField, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, SectionLabel, overlayStyle, PopupContainer} from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { SelectionControl,CircularProgress } from 'react-md';
import swal from "sweetalert";
import styled from 'styled-components';
import Loader from './Loader';
import axiosRetry from 'axios-retry';
import Popup from 'reactjs-popup';

const ResponsiveColumn = styled(Column)`
  padding: 0;
  margin: 0px 0 10px 20px;
  width: 300px;
  display: flex;
  @media screen and (max-width: 400px) {
      width: 250px;
  }
`;

const ResponsiveColumnDNS = styled(Column)`
  border: 1px solid #d3d3d3;
  padding: 0 15px 15px 15px;
  margin: 15px 0 10px 20px;
  width: 300px;
  display: flex;
  @media screen and (max-width: 400px) {
      width: 250px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 10px;
`;

const TableContainer = styled.div`
  white-space: nowrap;
`;

const TableLayout = styled(TableContainer)`
  margin-top: 10px;
  width: 100%;
`;

const ErrorLabel = styled.label`
  display:inline-block;
  width: auto;
  color: red;
  margin-top: 21px;
`;

const EmptyLabel = styled.label`
  display:inline-block;
  width: auto;
  margin-top: 21px;
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const ResponsivePopupContainer = styled(PopupContainer)`
  @media screen and (max-height: 600px) {
    height: 415px;
    overflow-y: auto;
  }
`;

const Container = styled.div`
  padding: 0px 20px 10px 20px;
`;
const TdKey = styled.td`
  border: 1px solid #ddd;
  text-align:center;
  padding-left: 5px;
  padding-right: 5px;
  @media screen and (max-width: 800px) {
    width:180px;
  }
`;

const TdValue =styled(Td)`
    width: 350px;
    max-height : 20px;
    margin-right : 0px;
    @media screen and (max-width: 500px) {
        width: 220px;
    }
`;

const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '32%',
    border: '2px solid #d7d7d7'
  };

const SectionHeaderResolvectl=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 0px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
  @media screen and (max-width: 800px) {
    padding: 5px 5px 1px 15px;

  }
`;

const TableButton =styled.button`
    background: transparent;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    padding: 1rem 1rem;
    position : relative;
    transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    &:focus {
    left: 0;
    width: 100%;
    bottom:0px;
    border-bottom: 3px solid #00d8ff;
    }
    &: hover {
    outline: 0;
    background-color: #f4f4f4;
    background-color: rgba(0,0,0,0.05);
    }
`;

const TabDiv = styled.div`
`;

const TableContainerDiv = styled.div`
  white-space: pre-wrap;
  width: 100%;
`;

const ResponsiveTable = styled(TableContainerDiv)`
    margin-top : 10px;
    overflow : auto;
    max-height:453px;
    @media screen and (max-width: 500px) {
    height: 232px;
  }
`;

const CenterDiv=styled.div`
  text-align: center;
  margin-bottom: -15px;
  padding: 10px 10px 0px 10px;
  font-size: 1.1em;
`;

const TableDiv = styled.div`
    border-bottom: 1px solid #ddd;
`;


  

const DNSSettings = (props) => {
    const [dns, setDns] = useState();
    const [configure, setConfigure] = useState();
    const [dnsLoading, setDnsLoading] = useState(true);
    const [readOnly, setReadOnly] = useState(true);
    const [interfaceList, setInterfaceList] = useState();
    const [interfaceError, setInterfaceError] = useState(true);
    const [interfaceLoading, setInterfaceLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [modalOpen, setModalOpen] =useState(false);

    useEffect(()=>{
      var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
      } else {
        setIsMobileBrowser(isMobileBrowser);
      }
    },[]);

    const getDNS = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount === 15) {
                  swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
          });
        client.get('/system/network/interface/dns/all')
        .then(res => {
            let globalDns = res.data.data.global_dns;
            if(globalDns.enabled === false && globalDns.servers.length === 0){
                setReadOnly(false);
                setDnsLoading(false);
                setConfigure(true);
            }else {
                let data = {}
                data.enabled = globalDns.enabled;
                data.servers = globalDns.servers.toString();
                    setDns(data);
                    setConfigure(false);
                    setDnsLoading(false);
            }
        }).catch(error => {
            console.log(error);
            setConfigure(true);
            setReadOnly(false);
            setDnsLoading(false);
        });
    }

    const getDNSStatus = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount === 15) {
                  swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
          });
          client.get('/system/network/interface/dns/status/all')
        .then(res => {
            setInterfaceList(res.data.data[0]);
            setInterfaceError(false);
            setInterfaceLoading(false);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setInterfaceError(true);
            setInterfaceLoading(false);
            setLoading(false);
            setInterfaceList();
            swal({ text: 'Could not fetch DNS information.', icon: 'error' });   
        });
    }

    const configuredHandle = () => {
        setDns(Constants.DNS_DEFAULT_VALUE);
        setConfigure(false);
    }

    const handleOnChange = (event) => {
        if (!readOnly) {
            let blockSpecialChar = event.target.value.replace(/^_/, "").replace(/^ /, "").replace(/^0/, "").replace(/^:/, "").replace(/^,/, "").replace(/[^.,:\w\s]/g, '');
            if (event.target.name === 'servers') {
                event.target.value = blockSpecialChar;
            }
            setDns({ ...dns, [event.target.name]: event.target.value });
        }
    }

    const handleDnsToggle = (value, event) => {
        const name = event.target.name;
        if (!readOnly) {
          setDns({ ...dns, [name]: value });
        } 
      };

    function ValidateIPaddress(ipaddress) {
        if (ipaddress === '') {
            return true;
        } else {
            if (Constants.IPv4_regex.test(ipaddress)) {
                return true
            } else if (Constants.IPv6_regex.test(ipaddress)) {
                return true;
            } else {
             swal('Please enter valid DNS server IP. ', { icon: 'error' });
             return false
         }

        }

    }

    const editDNS = () => {
        setReadOnly(false);
    }

    const onCancel =()=>{
        setReadOnly(true);
        getDNS();
    }

    const saveDNS = () => {
        let jsonData = Object.assign({}, dns);

        let global_dns = {...dns}
        let servers = global_dns.servers;
        let enabled = global_dns.enabled;

        if(enabled === undefined){
            enabled = false;
        }

        if(enabled === undefined || enabled === false && servers === '' || servers === undefined){
            servers = '';
        }

        if(enabled === true && servers === '' || servers === undefined) {
            swal('Please enter DNS server IP.', { icon: 'error'});
            return;
        }

        // Split and separate DNS by ",".
        var res = servers.toString().charAt(servers.toString().trim().length-1);
        let dnsServerIndex = [];

        if(res === ',') {
            swal('DNS server IP should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
            return;
        } else {

            let splitDNS = servers.toString().split(',');
            if(splitDNS.length > 3){
                swal(`Maximum 3 DNS server IPs can be configured.` , { icon: 'error'});
                return;
            }
            for (let i = 0; i < splitDNS.length; i++) {
                if (splitDNS[i].trim().includes(' ')) {
                    dnsServerIndex.push(splitDNS[i].trim());
                    swal(`DNS server IP "${dnsServerIndex}" should be separated by comma.` , { icon: 'error'});
                    return;
                }else if(!ValidateIPaddress(splitDNS[i])) {
                    return;
                }
            }
        };

        // Handle JSON.
        let dnsJson = [servers];
        let removeSpaceJson = dnsJson.toString().replace(/\s/g, "");
        let splitDnsJson = removeSpaceJson.toString().split(',');

        let duplicateDNS = false;

        // Block duplicate DNS.
        duplicateDNS = splitDnsJson.some((element, index) => {
            return splitDnsJson.indexOf(element) !== index;
        });
        if(duplicateDNS){
            swal(`Duplicate DNS server IP not allowed.` , { icon: 'error'});
            return;
        }

        let finalJson = {};
        finalJson.global_dns = Object.assign({}, finalJson);
        finalJson.global_dns.servers = splitDnsJson;
        finalJson.global_dns.enabled = enabled;
        finalJson.type = 'network';

        axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/interface/dns/all', finalJson)
            .then(res => {
                swal('Global DNS updated successfully.', { icon: 'success', timer: '2000'});
                setReadOnly(true);
                setTimeout(function(){ window.location.reload(); }, 1000);
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                } else {
                    swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                }
            });
    }

    const refreshInterfaces = (event) => {
        setLoading(true);
        document.getElementById('btnRefresh').textContent = 'Refreshing...';
        getDNSStatus();
      };
    
      useEffect(() => {
        if (document.getElementById('btnRefresh')) {
          document.getElementById('btnRefresh').textContent = 'Refresh';
        }
      }, [interfaceList]);

      const refreshInterfacesError = (event) => {
        setLoading(true);
        document.getElementById('btnRefreshError').textContent = 'Refreshing...';
        getDNSStatus();
      };
    
      useEffect(() => {
        if (document.getElementById('btnRefreshError')) {
          document.getElementById('btnRefreshError').textContent = 'Refresh';
        }
      }, [loading, interfaceList]);
       
      useEffect(()=>{
        getDNS();
        getDNSStatus();
    },[]);

    const onModelOpen =()=>{
        setModalOpen(true);
    }

    const closePopup = () => {
        setModalOpen(false);
      };

    return (
        <>
            <ContentDiv>
                <Wrapper style={{'padding': '5px'}}>
                    <SectionHeader>
                        <SectionLabel>Current DNS used by Interfaces</SectionLabel>
                    </SectionHeader>

                    <Popup closeOnDocumentClick={false} modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                        <ResolveCtlPopup closePopup={closePopup} />
                    </Popup>

                    <Loader isLoading={interfaceLoading} />
                    {interfaceError && !interfaceLoading &&
                    <Row style={{width: 'auto'}}>
                        <Column  style={{'display': 'flex', 'float': 'left', padding:'0', 'marginLeft': '20px', width: 'auto'}} >
                            <Button primary id="btnRefreshError" name="btnRefreshIfaces" style={{'marginLeft':'0'}} disabled={loading} onClick={() => refreshInterfacesError()}>Refresh</Button>
                        </Column>
                    </Row>}
                    {interfaceList && Object.keys(interfaceList).length === 0 &&
                    <Row style={{width: 'auto'}}>
                        <Column  style={{'display': 'flex', 'float': 'left', padding:'0', 'marginLeft': '20px', width: 'auto'}} >
                            <EmptyLabel style={{'width': 'auto'}}>No Interface found.</EmptyLabel>
                            <Button primary id="btnRefresh" name="btnRefreshIfaces" disabled={loading} onClick={() => refreshInterfaces()}>Refresh</Button>
                        </Column>
                    </Row>}
                    {interfaceList && Object.keys(interfaceList).length > 0 && 
                    <Row>
                        <ResponsiveColumn style={{'justifyContent':'flex-end'}}>
                            <Button primary id="btnRefresh" name="btnRefreshIfaces" style={{marginBottom:'0'}} disabled={loading} onClick={() => refreshInterfaces()}>Refresh</Button>
                            <Button primary id="ResolveCtl" name="ResolveCtl" style={{marginBottom:'0'}}  disabled={loading} onClick={onModelOpen}>Show Details</Button>
                        </ResponsiveColumn>
                    </Row>}
                    {interfaceList && Object.keys(interfaceList).length > 0 && 
                    <Row>
                        <ResponsiveColumn>
                            <TableLayout>
                                <table style={{ 'width': '100%', 'border-collapse': 'collapse'}}>
                                    <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                                        <Th style={{'padding': '10px','text-align': 'left'}}>Interface</Th>
                                        <Th style={{'padding': '10px'}}>DNS</Th>
                                    </tr>
                                    {Object.keys(interfaceList).map((key)=>{
                                        return(
                                            <tr>
                                                <Td style={{'padding': '10px', 'text-align': 'left'}}>{key}</Td>
                                                <Td style={{'padding': '10px'}} id={key}>{interfaceList[key] === '' ? '-' : interfaceList[key]}</Td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            </TableLayout>
                        </ResponsiveColumn>
                    </Row>}
                    {!interfaceLoading && 
                    <Row>
                    <div style={{'marginLeft': '20px'}}>
                        <MessageLabel style={{'text-size-adjust': '100%'}}>
                        <b>Note : </b>Current DNS of interface is affected by <br /> following settings:<br/>
                        <div style={{'marginLeft': '20px', 'marginTop': '5px'}}>
                            <li>Global DNS</li>
                            <li>Static DNS setting of Interface</li>
                            <li>DHCP Server configuration</li>
                        </div>
                        </MessageLabel>
                    </div>
                    </Row>}
                </Wrapper>
            </ContentDiv>
            <Spacer />
            <ContentDiv>
                <Wrapper style={{'padding': '5px'}}>
                    <SectionHeader>
                    <SectionLabel>Global DNS</SectionLabel>
                    </SectionHeader>
                    <Loader isLoading={dnsLoading} />
                    {configure && !dnsLoading &&
                        <div style={{'marginLeft': '5px'}}>
                            <Row>
                                <Label style={{'margin-left': '15px', 'margin-top': '10px' }}>Global DNS is not configured.</Label>
                            </Row>
                            <Row>
                                <Button primary onClick={configuredHandle}>Configure</Button>
                            </Row>
                        </div>}
                        {dns && !dnsLoading && !configure &&
                            <>
                            <ResponsiveColumn style={{'justifyContent': 'flex-start','marginBottom':'-15px'}}>
                                <SelectionControl style={{margin: '5px 0'}}  id="dns_enabled" type="switch" name="enabled" labelBefore="true"  label={dns.enabled ? 'Enabled' :'Disabled'} checked={dns.enabled ? true : false} onChange={handleDnsToggle} />
                            </ResponsiveColumn>
                           <Row>
                                <ResponsiveColumnDNS style={{flexDirection: 'column'}}>
                                    <div style={{display:'flex', 'marginTop': '15px'}}>
                                        <TextFieldLabel id='servers' style={{'margin-top': '10px'}}>DNS Server</TextFieldLabel>
                                        <TextFieldSmall id="servers" title={dns.servers} autoComplete="off" name="servers" readOnly={dns.enabled === true ? readOnly : readOnly || !readOnly} placeholder='8.8.8.8, 8.8.4.4' maxLength={125} value={dns.servers} onChange={handleOnChange} style={{'width': '100%', 'minWidth': '100px', 'textTransform':'lowercase'}} />
                                    </div>
                                </ResponsiveColumnDNS>
                            </Row>
                            <Row>
                            {readOnly ?
                                <ResponsiveColumn style={{'justifyContent': 'flex-end'}}>
                                    <Button primary onClick={editDNS}>Edit</Button>
                                </ResponsiveColumn> :
                                <ResponsiveColumn style={{'justifyContent': 'flex-end'}}>
                                    <Button primary onClick={saveDNS}>Save</Button>
                                    <Button id='btnCancle' danger onClick={onCancel}>Cancel</Button>
                                </ResponsiveColumn>}
                            </Row>
                        </>}
                </Wrapper>
            </ContentDiv>
        </>
    )
}

const ResolveCtlPopup = (props) => {
    const { closePopup } = props;
    const [tableData, setTableData] = useState([]);
    const [tableNames, setTableNames] = useState([]);
    const [selectedTable, setSelectedTable] = useState(null);
    const [retryResolveCtl, setRetryrResolveCtl] = useState(false);
    const [loading, setLoading] = useState(false);

    const getResovedCtlData = () => {
        setLoading(true);
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
            retries: 15,
            retryDelay: (retryCount, error) => {
                if (retryCount < 15 && retryCount > 5) {
                    setRetryrResolveCtl(true);
                } else if (retryCount === 15) {
                    setRetryrResolveCtl(false);
                    swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
        });
        client.get('/system/network/interfaces/resolvectl/status/all')
            .then(res => {
                let data = res.data.data;
                let tableData = data.map(item => {
                    const objName = Object.keys(item)[0];
                    const objValue = Object.values(item)[0];
                    const tableRow = Object.entries(objValue).map(([key, value]) => {
                        let newValue;
                        if (value.includes(',')) {
                            newValue = value.replace(/ /g, '').replace(/,/g, '\n');
                        } else {
                            newValue = value
                        }
                        return {
                            objName,
                            key,
                            value: newValue
                        } 
                    });
                    return tableRow;
                })
                setTableData(tableData);
                setTableNames(data.map(item => Object.keys(item)[0]));
                setSelectedTable(tableNames[0]);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setLoading(false);
                swal({ text: 'Could not fetch DNS information.', icon: 'error' });
            });
    }

    useEffect(() => {
        getResovedCtlData();
    }, [])

    useEffect(() => {
        setSelectedTable(tableNames[0]);
    }, [tableNames])

    const handleTableButtonClick = (event) => {
        const tableName = event.target.value;
        setSelectedTable(tableName);
    }

    const tables = tableData.map(table => {
        const tableName = table[0].objName;
        const tableBody = table.map(row => {
            const { key, value } = row;

            return (
                <>
                    <tr>
                        <TdKey style={{ 'fontWeight': '400', 'textAlign': 'left', 'padding': '7px' }}>{key}</TdKey>
                        <TdValue style={{ 'textAlign': 'left', 'padding': '7px'}}>{value}</TdValue>
                    </tr>
                </>
            );
        });
        return (
            <div>
                {tableName === selectedTable ? (
                    <div>
                        <ResponsiveTable>
                            <table style={{ 'min-width': '100%', 'borderSpacing': '0', 'tableLayout': 'fixed', 'borderCollapse': 'separate' }}>
                                <tbody>
                                    {tableBody}
                                </tbody>
                            </table>
                        </ResponsiveTable>
                    </div>
                ) : null}
            </div>
        );
    });

    return (
        <div>
            <ResponsivePopupContainer>
                <Container>
                    <SectionHeaderResolvectl style={{ 'display': 'flex' }}>
                        <SectionLabel>Resolvectl Status</SectionLabel>
                    </SectionHeaderResolvectl>
                    {
                        selectedTable ?
                            <div>
                                {tableNames.map(tableName => (
                                    <TabDiv style={{ 'display': 'inline-grid'}}>
                                        <TableButton style={{ borderBottom: tableName === selectedTable ? '3px solid #00d8ff' : '' }} selected={tableName === selectedTable} value={tableName} onClick={handleTableButtonClick}>
                                            {tableName.includes('(') ? tableName.split('(')[1].split(')')[0] : tableName}
                                        </TableButton>
                                    </TabDiv>
                                ))}
                                <TableDiv></TableDiv>
                                {selectedTable !== null ? tables : null}
                            </div> :
                            <>{loading &&
                                <div className="loading-msg">
                                    <label>{retryResolveCtl ? 'Retrying...' : 'Loading...'}</label>
                                    <CircularProgress />
                                </div>}</>
                    }
                    <CenterDiv>
                        <Button id="btnCancel" onClick={closePopup} >OK</Button>
                    </CenterDiv>
                </Container>
            </ResponsivePopupContainer>
        </div>
    );
}

export default DNSSettings;