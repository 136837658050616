import React, { useState, useEffect, useCallback } from 'react'
import { Tabs, Tab, TabList } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, LeftDiv, Spacer, TextField, MandatoryLabelStream, TextArea, SelectField, Row, Column, RightDiv, Button, ButtonWrapper } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import RecordingTab from '../components/RecordingTab';
//import AnalysisTab from '../components/AnalysisTab';
import BroadcastingTab from '../components/BroadcastingTab';
import CaptureScheduleTab from '../components/CaptureScheduleTab';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css"
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import ls from 'local-storage';
import styled from 'styled-components';
import axiosRetry from 'axios-retry';
import TabLeaflet from './TabLeaflet';
import TabMapBox  from './TabMapBox';

const ResponsiveTabList = styled.div`  
  @media screen and (max-width: 850px) {
    & .rwt__tab {
      padding: 11.5px;
    }
  }
  @media screen and (max-width: 450px) {
    & .rwt__tab {
      padding: 9.5px;
    }
  }
  @media screen and (max-width: 395px) {
    & .rwt__tab {
      padding: 7px;
    }
  }
  @media screen and (max-width: 380px) {
    & .rwt__tab {
      padding: 7px 4px;
    }
  }
`;

const SectionLabel = styled.label`
  display:inline-block;
  font-size: 1.3em;
  color: #0097f1;
  @media screen and (max-width: 400px) {
    margin-left : -14px;
  }
`;

const ResponsiveRightDiv = styled(RightDiv)`
    padding: 6px 0;
    @media screen and (max-width: 550px) {
        padding: 3px 0;
    }
    @media screen and (max-width: 550px) {
        padding: 0;
    }
`;

const ToggleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px;
    @media screen and (max-width: 1025px) {
        flex-direction: column;
        gap: 12px;
        margin-top: 30px;
    }
`;

const ToggleContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-top: 32px;
    @media screen and (max-width: 700px) {
        margin-top: 30px;
    }
    @media screen and (max-width: 550px) {
        margin-top: 25px;
    }
    @media screen and (max-width: 400px) {
        margin-top: 23px;
    }
`;

const ResponsiveWrapper = styled(Wrapper)`
    @media screen and (max-width: 750px) {
        font-size: 0.95rem;
    }
    @media screen and (max-width: 700px) {
        font-size: 0.9rem;
    }
    @media screen and (max-width: 550px) {
        font-size: 0.75rem;
    }
    @media screen and (max-width: 450px) {
        font-size: 0.59rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 0.56rem;
    }
`;

const SectionHeader=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;

  @media screen and (max-width: 400px) {
    padding: 5px 5px 12px 15px;
  }
`;
const SelectionControl1=styled(SelectionControl)`
    
    @media screen and (max-width: 1050px) {
        margin:2em 0 0 0em !important;
    }
`;
const SelectionControl2=styled(SelectionControl)`
display:flex;
margin:-7em 0 0 15em !important;
@media screen and (max-width: 1154px) {
    margin:-7em 0 0 12em !important;
}
@media screen and (max-width: 1050px) {
    margin:1.7em 0 0 0em !important;
}
`;

const AddStream = (props) => {
    const source = getSourceType();
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [locationInputs, setLocationInputs] = useState({'enabled_location': false});
    const [mapService, setMapService] = useState('');
    const [mapToken, setMapToken] = useState('');
    const [updateCamera, setUpdateCamera] = useState('');
    const [mapViewCheckboxes, setMapViewCheckboxes] = useState({streamCheckbox: false, deviceCheckbox: false});

    const onMapViewChange = useCallback((mapViewCheckboxes) => {
        setMapViewCheckboxes(mapViewCheckboxes);
    }, []);

    const getAccessToken = useCallback( () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location/map/all')
        .then(res =>{
            if(res.data.data.access_token === ' ' || res.data.data.access_token === ''){
                setMapToken('')
                setMapService(res.data.data.service_provider)
            } else {
                setMapToken(res.data.data.access_token)
                setMapService(res.data.data.service_provider)
            }
        }).catch(err => {
            console.log(err);
          });
    },[]);

    useEffect(() => {
        getAccessToken();
    },[getAccessToken]);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    const initialValues = source === 'MLIT' ? Constants.STACK_ATTR_MLIT_DEFAULT_VALUES : Constants.STACK_ATTR_DEFAULT_VALUES;
    if(source === "FTP"){
        initialValues["snapshot_refresh_interval"] = "10";
    }
    if(source === "SAFIE"){
        initialValues["snapshot_refresh_interval"] = "3";
    }
    const [videoDevices, setVideoDevices] = useState();
    const [inputs, setInputs] = useState(initialValues);
    const [streamList, setStreamList] = useState({});
    const [videoSize, setVideoSize] = useState();
    const [splitIntervalMaxVal, setSplitIntervalMaxVal] = useState();
    const [videoDeviceInfo, setVideoDeviceInfo] = useState();
    var recorderState = getRecorderState();
    //var analysisState = Constants.ANALYSIS_DEFAULT_VALUES;
    var broadcastingState = source === "SAFIE" || source === "FTP" ? Constants.FOG_BROADCASTER_DEFAULT_VALUES : Constants.BROADCASTING_DEFAULT_VALUES;
    const [selectedVideoDevice, setSelectedVideoDevice] = useState();
    var captureScheduleState;
    var captureDescreteState;
    const [configure, setConfigure] = useState(true);
    const [cameraOptions, setCameraOptions] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [selectedTab, setSelectedTab] = useState('recordingTab');
    const [rtspToggleDisabled, setRtspToggleDisable] = useState(true);
    const [captureType, setCaptureType] = useState("still_capture");


    function onTabChange(tabId){
        setSelectedTab(tabId);
    }

    const updateCaptureType = (newCaptureType) => {
        setCaptureType(newCaptureType);
      };

    useEffect(() =>{
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount === 15) {
                  swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
        });
          client.get('/system/status')
        .then(res =>{
            setVideoSize(res.data['data']['services']['tmpfs_usage']['/opt/scorer/cache/videos']['size']);
        })
        .catch(err => {
            console.log(err)
        })
    }, []);

    useEffect(() => {
        var sizeInMB = (videoSize / (1024*1024)).toFixed(2);
        let num = Math.floor(sizeInMB);
        let finalSize = num * (1 - 60 / 100);
        setSplitIntervalMaxVal(Math.floor(finalSize));
      },[videoSize]);

    function getSourceType(){
        if(props.match.path.includes("add-rtmp-stream")){
            return "RTMP";
        } else if(props.match.path.includes("add-rtsp-stream")){
            return "RTSP";
        } else if(props.match.path.includes("add-web-stream")){
            return "HTTP";
        } else if(props.match.path.includes("add-usb-stream")){
            return "USB";
        } else if(props.match.path.includes("add-rtp-stream")){
            return "RTP";
        } else if(props.match.path.includes("add-ftp-stream")){
            return "FTP";
        } else if(props.match.path.includes("add-safie-stream")){
            return "SAFIE";
        } else if(props.match.path.includes("add-mlit-stream")){
            return "MLIT";
        }
    }

    function getRecorderState(){
        let recorderDefaultValue;
        if(source === "RTMP"){
            recorderDefaultValue = Constants.RECORDER_RTMP_DEFAULT_VALUES
        } else if(source === "RTSP"){
            recorderDefaultValue = Constants.RECORDER_RTSP_DEFAULT_VALUES;
        } else if(source === "HTTP"){
            recorderDefaultValue = Constants.RECORDER_HTTP_DEFAULT_VALUES;
        } else if(source === "USB"){
            recorderDefaultValue = Constants.RECORDER_USB_DEFAULT_VALUES;
        } else if(source === "RTP"){
            recorderDefaultValue = Constants.RECORDER_RTP_DEFAULT_VALUES;
        } else if(source === "FTP"){
            recorderDefaultValue = Constants.RECORDER_FTP_DEFAULT_VALUES;
        } else if(source === "SAFIE"){
            recorderDefaultValue = Constants.RECORDER_SAFIE_DEFAULT_VALUES;
        } else if(source === "MLIT"){
            recorderDefaultValue = Constants.RECORDER_MLIT_DEFAULT_VALUES;
        }
        recorderDefaultValue["camera_type"] = source;
        return recorderDefaultValue;
    }

    useEffect(() => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
               if(retryCount === 15) {
                  swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
        });
          client.get('/stacks/all')
            .then(res => {
                const stream_list = res.data
                setStreamList(stream_list['data']['stacks'])
            })
    }, []);
    
    function getVideoDeviceData(deviceName){
        axios.get(Constants.EDGE_API_ENDPOINT + "/devices/video/" + deviceName)
            .then(res => {
                if(res.data["data"]){
                    setVideoDeviceInfo(res.data);
                    setSelectedVideoDevice(deviceName)
                }
            })
    }

    const handleOnChange = (event) => {
        event.persist();
        if(event.target.name === "stack_name"){
            event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
        } else if(event.target.name === "description"){
            if(event.target.value.length > 255){
                return;
            }
        }
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    // const handleVideoDeviceOnChange = (event) => {
    //     event.persist();
    //     setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    //     getVideoDeviceData(event.target.value);
    // }

    function isEmpty (val) {
        if ( val === undefined || val.trim() === "") {
            return true;
        } else {
            return false;
        }
    }

    function isIp(ip) {
        var arrIp = ip.split(".");
        if (arrIp.length !== 4) {
            return false;
        }
        for (let block of arrIp) {
            if (Number(block) < 0 && Number(block) > 255){
                return false;
            }
            if (block === "" || block === undefined || block.length > 3 ){
                return false
            }

        }
        return true
    }


    function validateStackName (stackName) {
        if (Object.keys(streamList).indexOf(stackName) !== -1) {
            swal({text:"Stream name already exist. Please try with another name.", icon: 'error'});
            return false;
        }

        if(isEmpty(stackName)) {
            swal({text:"Please enter stream name", icon: 'error'});
            return false;
        }
        return true;
    }

    function getDate(hours, minutes, seconds){
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        return date;
    }

    function prepareRequestJson(jsonData, stackName) {
        if (!validateStackName (stackName)){
            return;
        }

        delete jsonData["stack_name"];
        var services = {}
        jsonData["services"] = services;
        services["recorder1"] = Object.assign({}, recorderState);
        if(services["recorder1"].gpu_affinity === 'no_affinity' || services["recorder1"].gpu_affinity === 'system') {
            delete services["recorder1"].gpu_device;
        }
        if(!services["recorder1"].enable_hw_acceleration) {
            delete services["recorder1"].gpu_device;
            delete services["recorder1"].gpu_affinity;
        }
        if(services["recorder1"]["video_duration"] < parseInt('5')) {
            swal({text: "Please enter interval >= 5 sec" , icon: "error"});
            return;
        }
        if(source === "RTMP"){
            let streamKey = services["recorder1"]["rtmp_stream_key"];
            let bufferTime = services["recorder1"]["rtmp_source_buffer_time"];
            if((streamKey === "" || streamKey === undefined) && services["recorder1"]["rtmp_stream_key_type"] === "custom_rtmp_stream_key"){
                swal({text:"Please enter custom stream/Key", icon: 'error'})
                return;
            }
            if(isEmpty(services["recorder1"]["rtmp_source_url"])){
                swal({text:"Please enter server url", icon: 'error'});
                return;
            }
            if((bufferTime === "" || bufferTime === undefined)){
                swal({text:"Please enter buffer time", icon: 'error'})
                return;
            }
            let rtmpUrlFormat = /^(rtmp?):?\/\/./;
            if (!rtmpUrlFormat.test(services["recorder1"]["rtmp_source_url"])) {
                swal({text:"Server url is invalid", icon: 'error'});
                return;
            }
            if(services["recorder1"]["rtmp_server_type"] === "internal"){
                if(services["recorder1"]["rtmp_url_type"] === "mDNS"){
                    let mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
                    services["recorder1"]["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", mDnsName)
                } else {
                    let deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");
                    services["recorder1"]["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", deviceIP)
                }
                if(services["recorder1"]["rtmp_stream_key"] === ""){
                    services["recorder1"]["rtmp_stream_key"] = stackName;
                }
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
        } else if(source === "RTSP"){
            var rtspShortHeader = services["recorder1"]["rtsp_source_short_header"];
            var rtspUrlFormat = /^(rtsp|rtspt?):?\/\//
            if(recorderState["rtsp_source_url"] === undefined || recorderState["rtsp_source_url"] === '') {
                swal({text:"Please enter RTSP URL", icon: 'error'})
                return;
            } else {
                if(!rtspUrlFormat.test(recorderState["rtsp_source_url"])){
                    swal({text:"Invalid RTSP URL", icon: 'error'})
                    return;
                }
            }
            if(services["recorder1"]["rtsp_source_buffer_time"] === undefined || services["recorder1"]["rtsp_source_buffer_time"] ===""){
                swal({text:"Please enter buffer time", icon: 'error'})
                return;
            }
            if (services["recorder1"]["maximum_packet_delay_type"] === "auto") {
                services["recorder1"]["maximum_packet_delay"] = "auto";
            }

            if(services["recorder1"]["maximum_packet_delay"] === undefined || services["recorder1"]["maximum_packet_delay"] ===""){
                swal({text:"Please enter maximum packet delay in sec(s).", icon: 'error'})
                return;
            }else if( services["recorder1"]["maximum_packet_delay"] <=0){
                swal({text:"Please enter maximum packet delay >=1 (sec).", icon: 'error'})
                return;
            }
            
            if(services["recorder1"]["maximum_packet_delay_type"]==="auto"){
                let auto_maximum_packet =services["recorder1"]["rtsp_source_buffer_time"] * 2;
                services["recorder1"]["maximum_packet_delay"] = "auto"
            }
            if(services["recorder1"]["maximum_packet_delay_type"] === "auto"){
                let auto_maximum_packet = services["recorder1"]["rtsp_source_buffer_time"]
                services["recorder1"]["maximum_packet_delay"] = "auto"
            }

            if(rtspShortHeader === "true"){
                services["recorder1"]["rtsp_source_short_header"] = true;
            }else{
                services["recorder1"]["rtsp_source_short_header"] = false;
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
        }  else if(source === "HTTP"){
            var rtspShortHeader = services["recorder1"]["rtsp_source_short_header"];
            var rtspUrlFormat = /^(http|https?):?\/\//
            if(recorderState["http_source_url"] === undefined || recorderState["http_source_url"] === '') {
                swal({text:"Please enter HTTP URL", icon: 'error'})
                return;
            } else {
                if(!rtspUrlFormat.test(recorderState["http_source_url"])){
                    swal({text:"Invalid HTTP URL", icon: 'error'})
                    return;
                }
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
        } else if(source === "USB"){
            if (services["recorder1"]["video_fps"] === "auto") {
                services["recorder1"]["video_fps"] = services["recorder1"]["video_fps"]
            } else {
                services["recorder1"]["video_fps"] = services["recorder1"]["video_fps"].replace("fps", "/1")
            }
            var resolution = services["recorder1"]["resolution"];
            var regEx = /\((.*?)\)/
            var arr = regEx.exec(resolution)[1].split("x")
            services["recorder1"]["video_width"] = arr[0];
            services["recorder1"]["video_height"] = arr[1];
            delete services["recorder1"]["resolution"];
            // services["recorder1"]["video_device"] = jsonData["video_device"];
            if(Constants.AD_HW_VPU === 'NVIDIA' && services["recorder1"].video_source_type === 'UVC_H264') {
                delete services["recorder1"].gpu_device;
                delete services["recorder1"].gpu_affinity;
            }
            delete jsonData["video_device"];
        } else if(source === "RTP" || source === "MLIT"){
            if(Constants.AD_HW_VPU !== 'NVIDIA' && Constants.AD_HW_VPU !== 'MMAL'){
            if(services["recorder1"]['transport_encapsulation'] ==="MPEG2PS"){
                services["recorder1"]['enable_hw_acceleration'] = false;
            }
        }
            if(services["recorder1"]["enable_video_multicast"] === true){
                if(isEmpty(services["recorder1"]["video_multicast_address"])){
                swal({text:"Please enter video multicast address", icon: 'error'})
                return; 
                }
                if(!isIp(services["recorder1"]["video_multicast_address"])){
                    swal({text:"Please enter valid video multicast address", icon: 'error'})
                    return;
                }
                if(services["recorder1"]["video_interlace_mode"] === "mixed"){
                    delete services["recorder1"]["RTP_TS_PROG_DECODE"]
                }
            }
            if(services["recorder1"]["transport_encapsulation"] === 'auto'){
                delete services["recorder1"]["RTP_TS_PROG_DECODE"];
                delete services["recorder1"]["video_interlace_mode"];
                delete services["recorder1"]["video_payload_type"];
                delete services["recorder1"]["video_codec"];
            }
            if((services["recorder1"]["transport_encapsulation"] === "MPEG2TTS" || services["recorder1"]["transport_encapsulation"] === "MPEG2TS") && services["recorder1"]["video_interlace_mode"] === "mixed"){
                services["recorder1"]["RTP_TS_PROG_DECODE"] = true;
            }
            if(services["recorder1"]["transport_encapsulation"] === "MPEG2PS" ){
                delete services["recorder1"]["video_interlace_mode"];
                services["recorder1"]["RTP_TS_PROG_DECODE"] = true;
            }
            if(isEmpty(services["recorder1"]["video_port_rtp"])){
                swal({text:"Please enter video RTP port", icon: 'error'})
                return;
            } 
            if(isEmpty(services["recorder1"]["video_port_rtcp"]) && services["recorder1"]["enable_video_rtcp_muxed"] === false){
                swal({text:"Please enter video RTCP port", icon: 'error'})
                return;
            }
            if((services["recorder1"]["maximum_packet_delay"] === '') || (services["recorder1"]["maximum_packet_delay"] === null) ){
                swal({text:"Please enter maximum packet delay in sec(s).", icon: 'error'})
                return;
            }else if(services["recorder1"]["maximum_packet_delay"] <=0){
                swal({text:"Please enter maximum packet delay >=1 (sec).", icon: 'error'})
                return;
            }
            if(services["recorder1"]["enable_audio"] === true){
                if(services["recorder1"]["enable_audio_multicast"] === true){
                    if(isEmpty(services["recorder1"]["audio_multicast_address"])){
                        swal({text:"Please enter audio multicast address", icon: 'error'})
                        return; 
                        }
                    if(!isIp(services["recorder1"]["audio_multicast_address"])){
                        swal({text:"Please enter valid audio multicast address", icon: 'error'})
                        return;
                    }
                }
                if(isEmpty(services["recorder1"]["audio_port_rtp"])){
                    swal({text:"Please enter audio RTP port", icon: 'error'})
                    return;
                }
                if(isEmpty(services["recorder1"]["maximum_packet_delay"])){
                    swal({text:"Please enter audio RTP port", icon: 'error'})
                    return;
                }
                if(isEmpty(services["recorder1"]["audio_port_rtcp"]) && services["recorder1"]["enable_audio_rtcp_muxed"] === false){
                    swal({text:"Please enter audio RTCP port", icon: 'error'})
                    return;
                }
            }

            let rtpPorts = []
            let video_port = services["recorder1"]["video_port_rtp"]
            let video_port_rtcp = services["recorder1"]["video_port_rtcp"]
            let audio_port = services["recorder1"]["audio_port_rtp"]
            let audio_port_rtcp = services["recorder1"]["audio_port_rtcp"]
            let inputPorts = [video_port, video_port_rtcp, audio_port, audio_port_rtcp]
            var port
            for (port of inputPorts) {
                if (port.length > 0){
                    rtpPorts.push(port)
                }
            }

            let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
            if (findDuplicates(rtpPorts).length > 0) {
                swal({text:"Duplicate ports entries not allowed", icon: 'error'});
                return;
            }
            if(Constants.AD_HW_VPU === 'NVIDIA' && services["recorder1"].transport_encapsulation === 'H264' || services["recorder1"].transport_encapsulation === 'MPEG2TS' || services["recorder1"].transport_encapsulation === 'MPEG2TTS') {
                delete services["recorder1"].gpu_device;
                delete services["recorder1"].gpu_affinity;
            }
            delete jsonData["video_device"];
            services["recorder1"]["camera_type"] = 'RTP';
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
        } else if(source === "FTP"){
            let port = services["recorder1"]["port"]
            if((port === "" || port === undefined) && services["recorder1"]["server_type"] === "external"){
                swal({text:"Please enter port", icon: 'error'})
                return;
            }
            let source_directory = services["recorder1"]["source_directory"];
            if((source_directory === "" || source_directory === undefined) && services["recorder1"]["source_directory_type"] === "custom_source_directory"){
                swal({text:"Please enter custom source directory", icon: 'error'})
                return;
            }
            let date_time_format = services["recorder1"]["date_time_format"]
            if((date_time_format === "" || date_time_format === undefined) && services["recorder1"]["ingest_time"] === "File Name"){
                swal({text:"Please enter date time format", icon: 'error'})
                return;
            }
            if(services["recorder1"]["server_type"] === "internal"){
                if(services["recorder1"]["url_type"] === "mDNS"){
                    let mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
                    services["recorder1"]["source_url"] = Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", mDnsName)
                } else {
                    let deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");
                    services["recorder1"]["source_url"] = Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", deviceIP)
                }
                if(services["recorder1"]["source_directory"] === ""){
                    services["recorder1"]["source_directory"] = stackName;
                }
                services["recorder1"]["port"] = Constants.FTP_SERVER_PORT;
            } else {
                delete services["recorder1"]["source_directory_type"];
                delete services["recorder1"]["url_type"];
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
 
            if(services["recorder1"]["enable_user_login"] === true){
                if(isEmpty(services["recorder1"]["username"])){
                    swal({text:`Please enter username`, icon: 'error'})
                    return;
                }
                if(isEmpty(services["recorder1"]["password"])){
                    swal({text:`Please enter password`, icon: 'error'})
                    return;
                }
            }
        } else if(source === "SAFIE"){
            services["recorder1"]["video_device"] = stackName;
            if(services["recorder1"]["capture_type"] === 'still_capture') {
                if(services["recorder1"]["still_capture_api"] === 'still') {
                    if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] === '00' && services["recorder1"]["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 1 min" , icon: "error"});
                        return;
                    } else if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] !== '00' && (services["recorder1"]["image_pull_hrs"] === '00' || services["recorder1"]["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 1 min" , icon: "error"});
                        return;
                    }
                } else if(services["recorder1"]["still_capture_api"] === 'thumbnail') {
                    if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] === '00' && services["recorder1"]["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    } else if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] < '30' && (services["recorder1"]["image_pull_hrs"] === '00' || services["recorder1"]["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    }
                } else if(services["recorder1"]["still_capture_api"] === 'live') {
                    if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] === '00' && services["recorder1"]["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    } else if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] < '30' && (services["recorder1"]["image_pull_hrs"] === '00' || services["recorder1"]["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    }
                }
            }
            
        } else {
            swal({text:"Unsupported source: " + source, icon: 'error'});
            return;
        }
        if(services["recorder1"]["enable_save_mp4"] === true){
            if(services["recorder1"]["video_split_mode"] === 'size'){
              if(services["recorder1"]["video_file_max_size"] > splitIntervalMaxVal) {
                swal({text:`Please enter split interval value <= ${splitIntervalMaxVal}MiB`, icon: 'error'})
                return;
              } else if(services["recorder1"]["video_file_max_size"] === "") {
                swal({text:"Please enter mp4 split interval value in MiB", icon: 'error'})
                return;
              } else {
                services["recorder1"]["video_file_max_size"] = services["recorder1"]["video_file_max_size"]*(1024*1024) + "";
              }
            } else if(services["recorder1"]["video_split_mode"] === 'time'){
              if(services["recorder1"]["video_duration"] === undefined || services["recorder1"]["video_duration"] ===""){
                swal({text:"Please enter mp4 split interval value in sec(s)", icon: 'error'})
                return;
              }
              // Save default video_file_max_size in bytes
              if("video_file_max_size" in services["recorder1"]){
                  if(services["recorder1"]["video_file_max_size"] === '') {
                      services["recorder1"]["video_file_max_size"] = 50*(1024*1024) + "";
                  } else  {
                      services["recorder1"]["video_file_max_size"] = services["recorder1"]["video_file_max_size"]*(1024*1024) + "";
                  }
              }
            }
          } else {
            // Save default video_file_max_size in bytes
              if("video_file_max_size" in services["recorder1"]){
                services["recorder1"]["video_file_max_size"] = services["recorder1"]["video_file_max_size"]*(1024*1024) + "";
              }
          }

        if(services["recorder1"]["save_interval"] === undefined || services["recorder1"]["save_interval"] ===""){
            swal({text:"Please enter save interval value in sec(s)", icon: 'error'})
            return;
        }

        /*if(analysisState != undefined){
            if(analysisState["inactivity_timeout"] === undefined || analysisState["inactivity_timeout"] ===""){
                swal({text:"Please enter inactivity timeout for algorithm")
                return;
            }
            if(analysisState["type"] == "cascade_tracker"){
                services["cascade_tracker1"] = analysisState;
            }else if(analysisState["type"] == "hog_tracker"){
                services["hog_tracker1"] = analysisState;
            }
        }*/

        if(broadcastingState !== undefined){
            if (broadcastingState["fog_enabled"] === true) {
                            if(broadcastingState["destinations"].length === 0) {
                    swal({text:"Please add destination", icon: 'error'});
                    return;
                }
                if(isEmpty(broadcastingState["stream_id"])) {
                    swal({text:"Please enter stream id", icon: 'error'});
                    return;
                }
                if(broadcastingState["frame_rate_logging"] === true){
                    if(isEmpty(broadcastingState["frame_rate_logging_interval"])) {
                        swal({text:"Please enter frame rate logging interval", icon: 'error'});
                        return;
                    }
                    if(parseFloat(broadcastingState["frame_rate_logging_interval"])===0.0) {
                        swal({text:"frame rate logging interval should be > 0", icon: 'error'});
                        return;
                    }
                } else if(broadcastingState["frame_rate_logging"] === false){
                    if(parseFloat(broadcastingState["frame_rate_logging_interval"])===0.0){
                        broadcastingState["frame_rate_logging_interval"] = ""
                    }
                }
                if(isEmpty(broadcastingState["emit_interval"])) {
                    swal({text:"Please enter emit interval", icon: 'error'});
                    return;
                }
                if(isEmpty(broadcastingState["watchdog_timeout"])) {
                    swal({text:"Please enter watchdog timeout", icon: 'error'});
                    return;
                }
                if(parseInt(broadcastingState["watchdog_timeout"])< 0) {
                    swal({text:"Watchdog timeout should be >= 0", icon: 'error'});
                    return;
                }
                let fogBroadcasterService = Object.assign({}, broadcastingState);
                delete fogBroadcasterService["protocol"];
                delete fogBroadcasterService["destination_text"];
                if(fogBroadcasterService.stream_id === "auto"){
                    fogBroadcasterService["stream_id"] = stackName;
                }
                services["fog-broadcaster1"] = {
                    "enabled": broadcastingState["fog_enabled"],
                    "endpoint_type": broadcastingState["endpoint_type"],
                    "destinations": broadcastingState["destinations"],
                    "stream_id": broadcastingState["stream_id"],
                    "compression": broadcastingState["compression"],
                    "compression_quality": broadcastingState["compression_quality"],
                    "frame_rate_logging": broadcastingState["frame_rate_logging"],
                    "frame_rate_logging_interval": broadcastingState["frame_rate_logging_interval"],
                    "emit_interval": broadcastingState["emit_interval"],
                    "emit_interval": broadcastingState["emit_interval"],
                    "network_access": broadcastingState["network_access"],
                    "watchdog_timeout": broadcastingState["watchdog_timeout"],
                    "type": "fog_broadcaster",
                };
            }
            if (broadcastingState["legacy_mode"] === false && broadcastingState["frame_emitter_enabled"] === true) {
                if (broadcastingState["frame_emitter_stream_id"] === "") {
                    swal({ text: "Please enter stream id", icon: 'error' });
                    return;
                } else if (broadcastingState["frame_emitter_conn_ep"] === "") {
                    swal({ text: "Please enter destination endpoint", icon: "error" });
                    return;
                }
            }

            if(broadcastingState["rtmp_enabled"] === true){
                let rtmpUrlFormat = /^(rtmp?):?\/\//
                if(isEmpty(broadcastingState["rtmp_url"])) {
                    swal({text:'Please enter stream url', icon: 'error'})
                    return;
                } else {
                    if(!rtmpUrlFormat.test(broadcastingState["rtmp_url"])){
                        swal({text:"Invalid stream url", icon: 'error'})
                        return;
                    }
                }
                if(isEmpty(broadcastingState["stream_key"])){
                    broadcastingState["stream_key"] = "";
                }
                services["rtmp-broadcaster1"] = {
                    "enabled": broadcastingState["rtmp_enabled"],
                    "rtmp_url": broadcastingState["rtmp_url"],
                    "stream_key": broadcastingState["stream_key"],
                    "type": "rtmp_broadcaster"
                }
            }
            if(broadcastingState["rtp_enabled"] === true){
                if(isEmpty(broadcastingState["rtp_server_ip"])){
                    swal({text:"Please enter server ip", icon: 'error'})
                    return;
                }
                if(isEmpty(broadcastingState["rtp_video_udp_port"])){
                    swal({text:"Please enter video port", icon: 'error'})
                    return;
                }
                services["rtp-broadcaster1"] = {
                    "enabled": broadcastingState["rtp_enabled"],
                    "rtp_server_ip": broadcastingState["rtp_server_ip"],
                    "rtp_video_udp_port": broadcastingState["rtp_video_udp_port"],
                    "rtp_audio_udp_port": broadcastingState["rtp_audio_udp_port"],
                    "type": "rtp_broadcaster",
                }
            } 

            if(broadcastingState["kinesis_enabled"] === true){
                if(isEmpty(broadcastingState["kinesis_streamer_stream_name"])) {
                    swal({text:"Please enter stream name", icon: 'error'})
                    return;
                }
                if(isEmpty(broadcastingState["kinesis_streamer_access_key_id"])) {
                    swal({text:"Please enter access key", icon: 'error'})
                    return;
                }
                if(isEmpty(broadcastingState["kinesis_streamer_secret_access_key"])) {
                    swal({text:"Please enter secret key", icon: 'error'})
                    return;
                }
                services["kinesis-streamer1"] = {
                    "enabled":broadcastingState["kinesis_enabled"],
                    "kinesis_streamer_access_key_id": broadcastingState["kinesis_streamer_access_key_id"],
                    "kinesis_streamer_secret_access_key": broadcastingState["kinesis_streamer_secret_access_key"],
                    "kinesis_streamer_stream_name": broadcastingState["kinesis_streamer_stream_name"],
                    "kinesis_streamer_region_name": broadcastingState["kinesis_streamer_region_name"],
                    "type": "kinesis_streamer"
                }
            } 

            if(source === "SAFIE" && captureType === 'still_capture'){
                broadcastingState["frame_emitter_enabled"] = false;
                broadcastingState["frame_emitter_conn_ep"] = [];
            }
           
            if (broadcastingState.frame_emitter_stream_id === "auto") {
                jsonData["services"]["recorder1"]["stream_id"] = stackName;
            }

            if (broadcastingState["legacy_mode"] === false && broadcastingState["frame_emitter_enabled"] === true) {
                jsonData["services"]["recorder1"]["legacy_mode"] = broadcastingState["legacy_mode"];
                delete services["fog-broadcaster1"];
            }

            if (broadcastingState["legacy_mode"] === true && broadcastingState["fog_enabled"] === true) {
                jsonData["services"]["fog-broadcaster1"]["legacy_mode"] = broadcastingState["legacy_mode"];
                broadcastingState["frame_emitter_enabled"] = false;
                broadcastingState["frame_emitter_conn_ep"] = [];
            }

            jsonData["services"]["recorder1"]["hls_enabled"] = broadcastingState["hls_enabled"];
            jsonData["services"]["recorder1"]["target_duration"] = broadcastingState["target_duration"];
            jsonData["services"]["recorder1"]["playlist_length"] = broadcastingState["playlist_length"];

            jsonData["webrtc_enabled"] = broadcastingState["webrtc_enabled"];
            jsonData["rtsp_enabled"] = broadcastingState["rtsp_enabled"];

            jsonData["services"]["recorder1"]["frame_emitter_enabled"] = broadcastingState["frame_emitter_enabled"];
            jsonData["services"]["recorder1"]["frame_emitter_conn_ep"] = broadcastingState["frame_emitter_conn_ep"];
        }

        if(locationInputs !== undefined) {
            let locationData = Object.assign({}, locationInputs);
            if(locationData.enabled_location) {
                if(locationData.latitude === undefined || locationData.latitude === '' || locationData.latitude === '0.00000' || locationData.latitude === '0.0000'|| locationData.latitude === '0.000'|| locationData.latitude === '0.00' || locationData.latitude === '0.0' || locationData.latitude === '0' || locationData.latitude === 0){
                    swal('Please enter latitude.', { icon: 'error'});
                    return;
                }
                if(locationData.longitude === undefined || locationData.longitude === '' || locationData.longitude === '0.00000' || locationData.longitude === '0.0000'|| locationData.longitude === '0.000'|| locationData.longitude === '0.00'|| locationData.longitude === '0.0'|| locationData.longitude === '0' || locationData.longitude === 0 ){
                    swal('Please enter longitude.', { icon: 'error'});
                    return;
                }
                if(locationData.latitude.length === 1 && locationData.latitude === '-'){
                    swal('Invalid Latitude.', { icon: 'error'});
                    return;
                }
                if(locationData.longitude.length === 1 && locationData.longitude === '-'){
                    swal('Invalid Longitude.', { icon: 'error'});
                    return;
                }
            }
            jsonData["location"] = locationData;
        }

        if(captureScheduleState !== undefined && captureDescreteState !== undefined){
            let weekdays = "";
            Object.keys(Constants.WEEK_DAYS).map((week_day) => {
                if(captureScheduleState[week_day] === true) {
                    weekdays += ((Constants.WEEK_DAYS[week_day]) + ",");
                }
            })
            weekdays = weekdays.replace(/,+$/, "");
            if(captureScheduleState.enabled){
                if(weekdays === ""){
                    swal({text:"Please select capture schedule day.", icon: 'error'});
                    return;
                }
            }

            let startTimeArr = captureScheduleState.start_time.split(':');
            let endTimeArr = captureScheduleState.end_time.split(':')
            let startTime = getDate(parseInt(startTimeArr[0]), parseInt(startTimeArr[1]), 0);
            let endTime = getDate(parseInt(endTimeArr[0]), parseInt(endTimeArr[1]), 0);
            let scheduleInSeconds = (endTime.getTime() - startTime.getTime())/1000;
            if(captureDescreteState.enabled){
                let intervalHours = parseInt(captureDescreteState.interval_hours);
                let intervalMinutes = parseInt(captureDescreteState.interval_minutes);
                let intervalSeconds = parseInt(captureDescreteState.interval_seconds);
                if( intervalHours === 0 && intervalMinutes === 0 && intervalSeconds === 0){
                    swal({text:"Please select discrete capture interval.", icon: 'error'});
                    return;
                }
                let intervalInSeconds = (intervalHours * 60 * 60) + (intervalMinutes * 60) + intervalSeconds;
                if(intervalInSeconds > scheduleInSeconds){
                    swal({text:"Discrete capture interval should be less than or equal to schedule duration.", icon: 'error'});
                    return;
                } else {
                    let captureHours = parseInt(captureDescreteState.capture_hours);
                    let captureMinutes = parseInt(captureDescreteState.capture_minutes);
                    let captureSeconds = parseInt(captureDescreteState.capture_seconds);
                    if( captureHours === 0 && captureMinutes === 0 && captureSeconds === 0){
                        swal({text:"Please select discrete capture duration.", icon: 'error'});
                        return;
                    }
                    let captureDurationInSeconds = (captureHours * 60 * 60) + (captureMinutes * 60) + captureSeconds;
                    if(captureDurationInSeconds > intervalInSeconds){
                        swal({text:"Discrete capture duration should be less than or equal to interval.", icon: 'error'});
                        return;
                    }
                }
            }

            const addSeconds = (timeString) => {
                const [hours, minutes, seconds] = timeString.split(':');
                if (!timeString.includes(':')) {
                    return timeString + ':00';
                }
                if (seconds === undefined && (hours !== '00' || minutes !== '00')) {
                    return timeString + ':00';
                }
                if (seconds === undefined && (hours == '00' || minutes == '00')) {
                    return timeString + ':00';
                }
                return timeString;
            };

            jsonData["capture_schedule"] = {};
            jsonData["capture_schedule"]["enabled"] = captureScheduleState["enabled"];
            jsonData["capture_schedule"]["start_time"] = addSeconds(captureScheduleState["start_time"]);
            jsonData["capture_schedule"]["end_time"] = addSeconds(captureScheduleState["end_time"]);
            jsonData["capture_schedule"]["weekdays"] = weekdays;
            jsonData["capture_schedule"]["discrete_capture"] = captureDescreteState;
        }

        return jsonData
    }

    const cancelAddStream = (event) => {
        window.history.back();
    }
    
    const handleMapViewLocalStorage = useCallback((stackName) => {
        if (localStorage.getItem('mapViewCheckboxesAddStreamPage') !== null) {
            const {streamCheckbox} = JSON.parse(localStorage.getItem('mapViewCheckboxesAddStreamPage'));
            if (streamCheckbox !== mapViewCheckboxes.streamCheckbox) {
                localStorage.setItem('mapViewCheckboxesAddStreamPage', JSON.stringify(mapViewCheckboxes));
            }
            localStorage.setItem(`mapViewCheckboxesStreamPage${stackName}`, localStorage.getItem(`mapViewCheckboxesAddStreamPage`));
            localStorage.removeItem(`mapViewCheckboxesAddStreamPage`);
        }
        if (localStorage.getItem('addStreamMapView') !== null) {
            localStorage.setItem(`viewStreamMapView${stackName}`, localStorage.getItem(`addStreamMapView`));
            localStorage.removeItem(`addStreamMapView`);
        }
    }, [mapViewCheckboxes]);

    const handleSubmit = (event) => {
        if(event)
            event.preventDefault();

        var jsonData = Object.assign({}, inputs)
        
        var stackName = jsonData["stack_name"];

        jsonData = prepareRequestJson(jsonData, stackName)
        if(jsonData===undefined){
            return;
        }
        axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all", jsonData)
        .then(res => {
            handleMapViewLocalStorage(stackName);
            props.history.push("./stream-viewer");
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal({text:"Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const snapshotIntervalCallback = (intervalTime) => {
        setInputs({...inputs, ["snapshot_refresh_interval"]: intervalTime});
    }

    const deviceListCheckCallBack = (isList) => {
        setCameraOptions(isList);
    }

    const isSafieConfigurCallBack = (configured) => {
        setConfigure(configured);
    }

    const recorderStateCallback = (stateData) => {
        recorderState = stateData;
    }

    const changeCameraName = (stateData) => {
        if(source === 'SAFIE' && recorderState !== undefined && recorderState.camera_name) {
            setUpdateCamera(stateData);
        }
    }

    /*const analysisStateCallback = (stateData) => {
        analysisState = stateData
    }*/

    const broadcastingStateCallback = (stateData) => {
        broadcastingState = stateData
    }

    const locationStateCallback = useCallback((stateData) => {
        setLocationInputs(stateData);
    }, [])

    const captureScheduleStateCallback = (stateData1, stateData2) => {
        captureScheduleState = stateData1
        captureDescreteState = stateData2
    }

    const handleToggleChange = (value, event) => {
        setInputs({...inputs, [event.target.id]: value});
    };

    const handleSelectTab = (e) => {
        if(e !== undefined) {
            setTimeout(() => {
                setShowMap(true);
            }, 100);
        }
    }

    const getRTSPToggleState = (state) => {
        setRtspToggleDisable(state);
    }

    const getSelectedUSBDevice = (state) => {
        setSelectedVideoDevice(state)
    }

    return (
            <ContentDiv>
                <ResponsiveWrapper>
                    <SectionHeader>
                        <SectionLabel>Stream Information</SectionLabel>
                        <ResponsiveRightDiv>
                            <SelectionControl style={{height: 'auto'}} id="enabled" type="switch" label={inputs.enabled? "Enabled" :"Disabled"} name="enabled" labelBefore="true" checked={inputs.enabled || false} onChange={handleToggleChange}/>
                        </ResponsiveRightDiv>
                    </SectionHeader>
                    <Row>
                        <Column>
                           <MandatoryLabelStream>Stream Name</MandatoryLabelStream>
                           <TextField name="stack_name" type="text" value={inputs.stack_name} onChange={handleOnChange} maxLength= '63' />
                            <Label style={{"padding": "10px 0px 0px 0px"}}>Description</Label>
                            <TextArea name="description" rows="1" value={inputs.description} onChange={handleOnChange}/>
                        </Column>
                    </Row>
                </ResponsiveWrapper>
                <Spacer/>
                <ResponsiveWrapper>
                    <Tabs defaultTab="recordingTab" onChange={onTabChange}>
                        <TabList>
                            <ResponsiveTabList>
                                <Tab tabFor="recordingTab" className={`rwt__tab`}>Recording</Tab>
                                {/* <Tab tabFor="analysisTab" className={`rwt__tab`}>Analysis</Tab> */}
                                <Tab tabFor="broadcastingTab" className={`rwt__tab`}>Broadcasting</Tab>
                                <Tab tabFor="captureScheduleTab" className={`rwt__tab`}>Capture Schedule</Tab>
                               <Tab tabFor="geoLocation" className={`rwt__tab`} onClick={handleSelectTab}>Stream Location</Tab>
                            </ResponsiveTabList>
                        </TabList>
                        { ((source ==="USB") || source === "RTSP" || source === "HTTP" || source === "FTP" || source === "RTMP" || source === "RTP" || source === "SAFIE" || source === "MLIT") &&
                        <div>
                        <RecordingTab setRTSPToggleState={getRTSPToggleState} selectedVideoDevice={getSelectedUSBDevice} changeCameraName={changeCameraName} parentCallback={recorderStateCallback} isList={deviceListCheckCallBack} isConfigue={isSafieConfigurCallBack} intervalCallback={snapshotIntervalCallback} stackName={inputs.stack_name} data={recorderState} data2={videoDeviceInfo} videoDevice={selectedVideoDevice} captureType={captureType} updateCaptureType={updateCaptureType} readOnly={false}/>
                        {/* <AnalysisTab parentCallback={analysisStateCallback} data={analysisState} readOnly={false}/> */}
                        <BroadcastingTab parentCallback={broadcastingStateCallback} data={broadcastingState} readOnly={false} source={source} inputStackName={inputs.stack_name} captureType={captureType} />
                        <CaptureScheduleTab parentCallback={captureScheduleStateCallback} data={captureScheduleState} readOnly={false}/>
                        <TabLeaflet updateCamera={updateCamera} locationInputs={locationInputs} showMap={showMap} parentCallback={locationStateCallback} isViewPage={false} isSafie={source === 'SAFIE'} recorderState={recorderState} onMapViewChange={onMapViewChange} />
                        </div>
                        }
                    </Tabs>
                    {(selectedTab === 'recordingTab' || selectedTab === 'broadcastingTab' || selectedTab === 'captureScheduleTab' || selectedTab === 'geoLocation') &&
                    <ButtonWrapper style={selectedTab === 'geoLocation' ? {maxWidth: '882px'} : {}}>
                    <Button id='btndisabled' primary onClick={handleSubmit} disabled={source === "SAFIE" ? (!configure ? true : (cameraOptions ? false : true)) : source==="USB" && selectedVideoDevice===undefined ? true :  false }>Submit</Button>
                        <Button id="cancelButton" onClick={cancelAddStream}>Cancel</Button>
                    </ButtonWrapper>}
                </ResponsiveWrapper>
            </ContentDiv>
            )
}

export default AddStream;