import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios';
import { Label, TextField, SelectField, Row, Column, InfoIcon, PopupContainer, MandatoryLabelStream, MessageLabel, InfoEyeButton, Button, RightDiv, PopupButtons, CenterDiv, SectionHeader, SectionLabel, overlayStyle, FieldSet } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import ls from 'local-storage';
import FieldLabel from './FieldLabel';
import PasswordField from './PasswordField';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import {isEmptyNumber, isEmpty} from '../utils/Utils';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from './Loader';
import { Multiselect } from 'multiselect-react-dropdown';

const InnerContainer = styled.div`
    display: flex;
    padding: 3px 0px 10px 0px;
`;

const TextFieldLabel = styled(Label)`
    white-space : nowrap;
`;
const TextFieldLabelAudio = styled(Label)`
    white-space : nowrap;
    @media screen and (max-width: 410px) {
        margin: 7px 0 0 10px;
    }
`;

const MessageContaier = styled.div`
  padding: 5px 5px 1px 5px;
`;

const TextFieldMandatoryField = styled(TextFieldLabel)`
    &:after {
        font-weight: bold;
        content:" *";
        color: #EE4B2B;
    }
    width: 175px;
    flex-shrink: 0;
    @media screen and (max-width: 550px) {
        width: 146px;
    }
    @media screen and (max-width: 450px) {
        width: 130px;
    }
    @media screen and (max-width: 410px) {
        width: 115px;
    }
`;

const LoginMandatoryFieldLabel = styled(TextFieldMandatoryField)`
    width: 140px;
    margin: 7px 0px 0px 0px;
    @media screen and (max-width: 550px) {
        width: 130px;
    }
    @media screen and (max-width: 450px) {
        width: 82px;
    }
`;

const PortTextField = styled(TextField)`
    width: 105px;
    @media screen and (max-width: 750px) {
        width: 94px;
    }
    @media screen and (max-width: 550px) {
        width: 78px;
    }
    @media screen and (max-width: 450px) {
        width: 72px;
    }
    @media screen and (max-width: 400px) {
        width: 70px;
    }
`;

const SnapShotDiv=styled.div`
@media screen and (max-width: 1400px) {
    // margin-top:3px;
   font-size: 15.4px !important; 
}
    @media screen and (max-width: 600px) {
        margin-top:1px;
       font-size: 12.6px !important; 
  }
    @media screen and (max-width: 450px) {
       font-size: 7.84px !important; 
       margin-top :2px;
  }
`;

const SnapShotDiv1=styled.div`
    @media screen and (max-width: 1400px) {
       font-size: 15.4px !important; 
  }
    @media screen and (max-width: 600px) {
        margin-top:3px;
       font-size: 12.6px !important; 
  }
    @media screen and (max-width: 450px) {
       font-size: 7.84px !important; 
       margin-top :3px;
  }
`;

const ResponsiveSelectionControl = styled(SelectionControl)`
    width: 170px;
    @media screen and (max-width: 1450px) {
        width: 168px;
    }
    @media screen and (max-width: 680px) {
        width: 150px;
    }
    @media screen and (max-width: 450px) {
        width: 134px;
    }
    @media screen and (max-width: 380px) {
        width: 125px;
    }
`;

const ResponsiveLabel = styled(Label)`
    width: 180px;
    @media screen and (max-width: 450px) {
        width: 130px;
    }
`;

const ResponsivTextLabel = styled(TextFieldLabel)`
    width: 175px;
    flex-shrink: 0;
    @media screen and (max-width: 550px) {
        width: 146px;
    }
    @media screen and (max-width: 450px) {
        width: 130px;
    }
    @media screen and (max-width: 410px) {
        width: 115px;
    }
`;

const ResponsiveRow = styled(Row)`
    display: flex;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
    @media screen and (max-width: 410px) {
        align-items: center;
    }
`;

const ResponsiveColumn = styled(Column)`
    @media screen and (max-width: 1200px) {
        width: 580px;
        float: unset;
    }
    @media screen and (max-width: 850px) {
        width: 506px;
    }
    @media screen and (max-width: 760px) {
        width: 500px;
    }
    @media screen and (max-width: 750px) {
        width: 442px;
    }
    @media screen and (max-width: 700px) {
        width: 420px;
    }
    @media screen and (max-width: 680px) {
        width: 425px;
    }
    @media screen and (max-width: 550px) {
        width: 370px;
    }
    @media screen and (max-width: 450px) {
        width: 330px;
    }
    @media screen and (max-width: 410px) {
        width: 295px;
        margin-right : 6px;
    }
    @media screen and (max-width: 380px) {
        width: 290px;
        margin-right : 6px;
    }
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.8);
      background: transparent !important;
    }
  }
`;

const ColumnGenralComp=styled.div`
// width: 50%;
float:left;
padding: 7px 2%
`;

const ButtonStyled =styled.button`
background: gray;
color: #ffffff;
font-size: 10px;
margin: 1em 0em 1em 1em;
padding: -0.6em 0em;
border: 0px solid #FF7F50;
border-radius: 3px;
opacity: 0.7;
cursor: pointer;

@media screen and (max-width: 1900px)  {
    margin: 10px 0em 2em 1em;
}
@media screen and (max-width: 1269px)  {
    font-size: 8px;
    margin: 13px 0em 2em 1em;
}
@media screen and (max-width: 1025px)  {
    font-size: 9px;
    margin: 13px 0em 2em 1em;
}
@media screen and (max-width: 710px)  {
    font-size: 6px;
    margin: 15px 0em 2em 1em;
}

@media screen and (max-width: 550px)  {
    font-size: 4px;
    margin: 16px 0em 4em 1em;
}
@media screen and (max-width: 380px)  {
    font-size: 5px;
    margin: 13px 0em 3em 0em;
}
`;

const ResolutionDiv=styled.div`
margin: 20px 0 0 30px;
@media screen and (max-width: 420px)  {
    font-size: 10px;
    margin:20px 0 0 12px;
}
`;

const Column1 = styled.div`
  width: 50%;
  float:left;
  padding: 2.5px 2%
`;

const TextFieldLabel1 = styled(Label)`
    white-space : nowrap;
    margin-right : 45px;
    @media screen and (max-width: 750px) {
        margin-right: 40px;
    }
    @media screen and (max-width: 500px) {
        margin-right: 34px;
    }
    @media screen and (max-width: 550px) {
        margin-right: 34px;
    }
    @media screen and (max-width: 450px) {
        margin-right: 28px;
    }
    @media screen and (max-width: 400px) {
        margin-right: 28px;
    }
    @media screen and (max-width: 360px) {
        margin-right: 28px;
    }
`;

const SelectField3 = styled.select`
  margin-left: 133px;
  width: 100%;
  font-size: 1em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
//   margin: 0;
  @media screen and (max-width: 410px) {
    margin-left: 92px;
    }
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  };
  &:enabled {
    background: #ffffff;
  }
  &:focus {
    outline: none;
    border-color: rgb(137, 188, 225);
  }
`;

const TextField1 = styled.input`
  width: 100%;
  font-size: 1.0em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
  margin: 0;
  padding-left : 125px;
  @media screen and (max-width: 410px) {
    padding-left: 76px;
}
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  }
  &:focus {
    outline: none;
    border-color: rgb(137, 188, 225);
  }
  &:read-only::placeholder {
    color: #f2f2f2;
  }
`;

const LabelHour = styled.label`
  display:inline-block;
  margin-bottom:5px;
  @media screen and (max-width: 410px) {
    margin-right: 8.5px;
  }
`;

const FieldSetTime = styled.fieldset`
  margin-top: 15px;
  margin-bottom: 10px;
  @media screen and (max-width: 410px) {
    margin-top: -22px;
  }
`;

const FieldSetRecording =styled.fieldset`
@media screen and (max-width: 410px) {
    margin-top: -21px !important;
  }
`;

const RecordingTab = (props) => {
    const history = useHistory();
    const [gpuList, setGpuList] = useState([]);
    const [cameraOptions, setCameraOptions] = useState();
    const [safieLoading, setSafieLoading] = useState(true);
    const [safieDeviceLoading, setSafieDeviceLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [mp4Details, setMp4Details] = useState();
    const [readOnly, setReadOnly] = useState(props.readOnly);
    const [cameraStatus, setCameraStatus] = useState();
    var isViewPage = props.readOnly;
    const [inputs, setInputs] = useState();
    var initialValues = props.data;
    var stackName = props.stackName;
    var stackStatus = props.stackStatus;
    // var updateCaptureType = props.updateCaptureType;
    var streamStatusData = props.streamStatusData;
    var source = undefined;
    const [videoDeviceInfo, setVideoDeviceInfo] = useState();
    // const [selectedVideoDevice, setSelectedVideoDevice] = useState();
    const [videoSourceType, setVideoSourceType] = useState();
    const [safieLiveStreamList, setSafieLiveStreamList] = useState();
    const [resolutions, setResolutions] = useState();
    const [frameRates, setFrameRates] = useState();
    const [videoDevice, setVideoDevice] = useState(props.videoDevice);
    const [videoDevices, setVideoDevices] = useState();
    const [videoDevicesAll, setVideoDevicesAll] = useState();
    const [videoSize, setVideoSize] = useState();
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [splitIntervalMaxVal, setSplitIntervalMaxVal] = useState();
    const [serviceChange, setServiceChange] = useState({"recorder": false});
    const mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
    const deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");
    let selectedVideoDevice = props.selectedVideoDevice;
    const switchBorderStyle = { borderRadius: 2 }
    const switchBackgroundStyle =   {
                                base: 'rgb(33,150,243)',
                                hover: 'rgb(33,150,243)'
                            }
    const [devcieName, setDeviceName] = useState();
    const [noVideoDevice, setNoVideoDevice] = useState(false);
    var updateCaptureType = props.updateCaptureType;
    let captureType = props.captureType;

    // For Add Stream
    if(props.readOnly == false){
        source = initialValues.camera_type;
    } else { // For View Stream
        if(initialValues != undefined){
            initialValues = props.data;
            source = initialValues.camera_type;
        }
    }
    // Update parent with initialValues
    props.parentCallback(inputs);

    const [configure, setConfigure] = useState(false);
    const [videosInfomations, setViedosInformation] = useState();

    const getSafieLiveStreamList = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/all')
        .then(res => {
            const stacks = res.data["data"]["stacks"];
            Object.keys(stacks).map((stack_name) => {
                if(stacks[stack_name]["action"] === "DeleteStack"){
                    delete stacks[stack_name];
                }
                 else if(stacks[stack_name]["services"]["recorder1"]["camera_type"] !== "SAFIE" ){
                    delete stacks[stack_name];
                }                
            });
            let list = [];
            Object.keys(stacks).map((stack_name) => {
                if(stacks[stack_name]["services"]["recorder1"]["capture_type"] !== "live_streaming") {
                    delete stacks[stack_name];
                }
		else {
                    list.push(stacks[stack_name]["services"]["recorder1"]["camera_name"]);
		}
            },[]);
            setSafieLiveStreamList(list);   
        }).catch(error => {
           console.log(error);
        });
    }

    useEffect(() => {
        if(source === 'SAFIE'){
            getSafieLiveStreamList();
        }
    },[]);

    const getConfigDetails = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/safie/all')
        .then(res => {
            if(res.data.data.action === 'DeleteStack') {
                setConfigure(false);
                setSafieLoading(false);
                props.isConfigue(false);
            } else {
                if(res.data.data.services["safie-settings1"].enabled === true ) {
                    setConfigure(true);
                    props.isConfigue(true);
                    setSafieLoading(false);
                } else {
                    setConfigure(false);
                    props.isConfigue(false);
                    setSafieLoading(false);
                }
            }
        })
        .catch(error => {
            console.log(error);
            setConfigure(false);
            setSafieLoading(false);
            props.isConfigue(false);
        });
    }

    useEffect(()=> {
        if(source === 'SAFIE') {
            getConfigDetails();
        }
    },[]);

    const getcameraStatus = useCallback((name) => {
        Object.keys(cameraOptions).map((i) => {
            if(cameraOptions[i].value === name ) {
                if(!cameraOptions[i].videoStreaming) {
                    setCameraStatus(true);
                } else {
                    setCameraStatus(false);
                }
            }
        })
    },[cameraOptions]);

    useEffect(() => {
        if(cameraOptions && source === 'SAFIE') {
            getcameraStatus(inputs.camera_name)
        }
    },[cameraOptions, source, inputs]);

    const contentStyle = {
        margin: 'auto',
        background: 'rgb(255, 255, 255)',
        width: isMobileBrowser ? '340px' :'560px',
        height: 'auto',
        padding: '5px',
        minWidth: '24%',
        border: '2px solid #d7d7d7'
      };

    useEffect(() =>{
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/status')
        .then(res =>{
            setVideoSize(res.data['data']['services']['tmpfs_usage']['/opt/scorer/cache/videos']['size']);
        })
        .catch(err => {
            console.log(err)
        })
    }, []);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
          setIsMobileBrowser(isMobileBrowser);
        } else {
          setIsMobileBrowser(isMobileBrowser);
        }
      },[]);

      useEffect(() =>{
        if(streamStatusData === undefined){
            return;
        } else {
            if(source === 'FTP') {
                return;
            } else {
                if(streamStatusData.services.recorder1 === undefined) {
                    setMp4Details('notFound');

                } else {
                    if(streamStatusData.services.recorder1.health_status === undefined) {
                        setMp4Details('notFound');
                    } else {
                        if('health_status' in streamStatusData.services.recorder1) {
                            setMp4Details(streamStatusData.services.recorder1.health_status.status_detail);
                        } else {
                            setMp4Details('notFound');
                        }
                    }
                    
                } 
            }
        }
    }, [streamStatusData]);

    const getSafieCameraDevices = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/devices/safie')
        .then(res =>{
            if(res.data.data === null) {
                setCameraOptions();
                props.isList(false);
                setSafieDeviceLoading(false);
            } else {
                let cameraList = [];
                Object.keys(res.data.data).map((index) => {
                    let innerObj = {};
                    innerObj.value = res.data.data[index].device_id;
                    innerObj.name = res.data.data[index].setting.name;
                    innerObj.videoStreaming = res.data.data[index].status.video_streaming;
                    cameraList.push(innerObj);
                    if(source === 'SAFIE' && (initialValues["camera_name"] === '' || initialValues["camera_name"] === undefined)) {
                        initialValues["camera_name"] = res.data.data[0].device_id;
                        setInputs(initialValues);
                    }
                });
                setCameraOptions(cameraList);
                props.isList(true);
                setSafieDeviceLoading(false);
            }  
        })
        .catch(err => {
            console.log(err);
            setSafieDeviceLoading(false);
        })
    }

    useEffect(() => {
        if(source === 'SAFIE') {
            getSafieCameraDevices();
	    }
    },[source === 'SAFIE'])

    useEffect(() => {
        if(source === "RTMP"){
            if(initialValues["rtmp_server_type"] === "internal"){
                initialValues["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER;
            }
            if(props.readOnly === false){
               initialValues["rtmp_stream_key"] = "";
            }
        }
        setInputs(initialValues)
    },[source === "RTSP" || source === "RTMP"])

    useEffect(() => {
        let videoSourceTypeData = Object.values({...videoSourceType, 0:props.data["video_source_type"]})
        let resolutionsData = Object.values({...resolutions, 0:props.data["resolution"]})
        let frameRatesData = Object.values({...resolutions, 0:initialValues["video_fps"]})
        setVideoSourceType(videoSourceTypeData)
        setResolutions(resolutionsData)
        setFrameRates(frameRatesData)
    },[(source==="USB") && (props.videoDevice === undefined)])

    useEffect(() => {
        // add stream 
        if(props.readOnly == false && source === 'USB'){
            setInfoOnDeviceChange();
        }
        //view stream
        else{
            if(source==="USB"){
                let videoSourceTypeData = Object.values({...videoSourceType, 0:initialValues["video_source_type"]})
                setVideoSourceType(videoSourceTypeData)
                let resolutionsData = Object.values({...resolutions, 0:initialValues["resolution"]})
                for (const[index, value] of (Object.keys(Constants.RESOLUTIONS)).entries()){
                    if(resolutionsData[0] == (Object.keys(Constants.RESOLUTIONS))[index]){
                        resolutionsData[0] = (Object.values(Constants.RESOLUTIONS))[index]
                    }
                }
                setResolutions(resolutionsData)
                let frameRatesData = Object.values({...resolutions, 0:initialValues["video_fps"]})
                addFrameRate(frameRatesData);
                setFrameRates(frameRatesData)
            }
            setDeviceName(initialValues.video_device);
            setInputs(initialValues)
        }
    },[videosInfomations])

    function setInfoOnDeviceChange(){

        if(videosInfomations !== undefined && !isViewPage){ // For Add Stream
                let videoInfoData = videosInfomations;
                let videoSourcesData = Object.keys(videoInfoData);
                let resolutionsData = Object.keys(videoInfoData[videoSourcesData[0]])
                let frameRatesData = videoInfoData[videoSourcesData[0]][resolutionsData[0]]
                addFrameRate(frameRatesData);
                setVideoDeviceInfo(videoInfoData)
                setVideoSourceType(videoSourcesData)
                setResolutions(resolutionsData)
                setFrameRates(frameRatesData)
                initialValues["video_device"] = inputs.video_device;
                initialValues["video_source_type"] = videoSourcesData[0]
                initialValues["resolution"] = resolutionsData[0]
                initialValues["video_fps"] = frameRatesData[0]
            } else if(isViewPage) { // For View Stream
                let videoInfoData = props.data2["data"];
                let videoSourcesData = Object.keys(videoInfoData);
                if(videoSourcesData.includes(props.data["video_source_type"])) {
                    let resolutionsData = Object.keys(videoInfoData[props.data["video_source_type"]])
                    let frameRatesData = videoInfoData[props.data["video_source_type"]][Constants.RESOLUTIONS[props.data["resolution"]]]
                    addFrameRate(frameRatesData);
                    setVideoDeviceInfo(videoInfoData)
                    setVideoSourceType(videoSourcesData)
                    setResolutions(resolutionsData)
                    setFrameRates(frameRatesData)
                    initialValues["video_source_type"] = props.data["video_source_type"]
                    initialValues["resolution"] = Constants.RESOLUTIONS[props.data["resolution"]]
                    initialValues["video_fps"] = props.data["video_fps"].replace("/1","fps")
                } else {
                    swal('Attached camera does not support video source type '+ props.data["video_source_type"] +'. Please update stream configuration to match with supported type', {icon: 'error'});
                    props.data["video_source_type"] = videoSourcesData[0];
                    let resolutionsData = Object.keys(videoInfoData[props.data["video_source_type"]])
                    let frameRatesData = videoInfoData[props.data["video_source_type"]][Constants.RESOLUTIONS[props.data["resolution"]]]
                    addFrameRate(frameRatesData);
                    setVideoDeviceInfo(videoInfoData)
                    setVideoSourceType(videoSourcesData)
                    setResolutions(resolutionsData)
                    setFrameRates(frameRatesData)
                    initialValues["video_device"] = inputs.video_device;
                    initialValues["video_source_type"] = videoSourcesData[0];
                    initialValues["resolution"] = Constants.RESOLUTIONS[props.data["resolution"]]
                    initialValues["video_fps"] = props.data["video_fps"].replace("/1","fps")
                }
            }
            setInputs(initialValues)
    }

    const handleOnChange = (event) => {
        event.persist();
        let data = {...inputs, [event.target.name]: event.target.value};
        if(event.target.name === "transport_encapsulation" && source !== 'MLIT'){
            let payloadType = '';
            if(event.target.value === "H264") {
                payloadType = Constants.RTP_H264_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                delete data.video_interlace_mode;
                data['video_codec'] = 'H264';
            } else if(event.target.value === 'auto'){
                payloadType = Constants.RTP_MPEG_PS_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_interlace_mode'] = 'auto';
                data['video_codec'] = 'auto';
            } else if (event.target.value === 'MPEG2PS') {
                payloadType = Constants.RTP_MPEG_PS_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_codec'] = 'MPEG2';
                delete data.video_interlace_mode;
            } else if(event.target.value === 'MPEG2TTS') {
                payloadType = Constants.RTP_MPEGTTS_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_interlace_mode'] = 'progressive';
                data['video_codec'] = 'H264';
            } else if(event.target.value === 'MPEG2TS') {
                payloadType = Constants.RTP_MPEG_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_interlace_mode'] = 'progressive';
                data['video_codec'] = 'H264';
            } 
            else {
                payloadType = Constants.RTP_MPEG_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_codec'] = 'MPEG2';
                delete data.video_interlace_mode;
            }
        } else if (event.target.name === "transport_encapsulation" && source === 'MLIT'){
            let payloadType ='';
            if(event.target.value ==='H264'){
                payloadType = Constants.RTP_H264_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                delete data.video_interlace_mode;
                data['video_codec'] = 'H264';
            } else if(event.target.value === 'auto'){
                payloadType = Constants.RTP_MPEG_PS_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_interlace_mode'] = 'auto';
                data['video_codec'] = 'auto';
            } else if(event.target.value === 'MPEG2PS'){
                payloadType = Constants.RTP_MPEG_PS_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_codec'] = 'MPEG2';
                delete data.video_interlace_mode;
            } else if(event.target.value === 'MPEG2TTS'){
                payloadType = Constants.RTP_MPEGTTS_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_interlace_mode'] = 'mixed';
                data['video_codec'] = 'H264';
            } else if(event.target.value === 'MPEG2TS'){
                payloadType = Constants.RTP_MPEG_PAYLOAD_TYPE;
                data['video_payload_type'] = payloadType;
                data['audio_payload_type'] = payloadType;
                data['video_interlace_mode'] = 'mixed';
                data['video_codec'] = 'H264';
            }
        }
        if(event.target.name === 'rtsp_source_user' && event.target.value.includes('$')) {
            data['rtsp_source_user'] = event.target.value.replace('$', ''); 
        }
        if(event.target.name === 'rtsp_source_pass'  && event.target.value.includes('$')) {
            data['rtsp_source_pass'] = event.target.value.replace('$', ''); 
        }
        if(event.target.name === 'rtmp_source_url' && event.target.value.includes('&')) {
            data['rtmp_source_url'] = event.target.value.replace('&', ''); 
        }
        if(event.target.name === 'rtmp_source_url'  && event.target.value.includes('$')) {
            data['rtmp_source_url'] = event.target.value.replace('$', ''); 
        }
        if( event.target.name === 'password') {     
            data['password'] = event.target.value.replace(/[{}()~*?"'\\^$|#\s]Refresh Interval (Secs)/g, '');
        }
        if(event.target.name ==='still_capture_api' && event.target.value === 'still') {
            data['image_pull_min'] = '01';
            data['image_pull_sec'] = '00';
            props.intervalCallback('60');
        } else if(event.target.name ==='still_capture_api' && event.target.value === 'thumbnail') {
            data['image_pull_min'] = '00';
            data['image_pull_sec'] = '30';
            props.intervalCallback('30');
        } else if(event.target.name ==='still_capture_api' && event.target.value === 'live') {
            data['image_pull_min'] = '00';
            data['image_pull_sec'] = '30';
            props.intervalCallback('30');
        }
        if (event.target.name === 'capture_type' && event.target.value === 'still_capture') {
            const selectedValue = event.target.value;
            updateCaptureType(selectedValue);
            delete data.enable_save_mp4;
            delete data.video_split_mode;
            delete data.video_duration;
            delete data.periodic_snapshot;
            delete data.periodic_snapshot_resolution;
            delete data.save_original_snapshot;
            delete data.video_device;
            delete data.video_file_max_size;
            delete data.video_source_type;
            delete data.specify_snapshot_resolution;
            delete data.enable_hw_acceleration;
            delete data.resolution;
            delete data.video_fps;
            if(Constants.AD_HW_VPU === 'NVIDIA') {
                delete data.gpu_affinity;
            }
            data['recorder_type'] = 'image_recorder';
            data['image_pull_min'] = '00';
            data['image_pull_sec'] = '30';
            data['image_pull_hrs'] = '00';
            data['still_capture_api'] = 'live';
            data['periodic_snapshot'] = true;
            data['save_interval'] = '120';
            data['enable_snapshot_time_overlay'] = false;
            props.setRTSPToggleState(true);
        }
        if(event.target.name === 'capture_type' && event.target.value === 'live_streaming') {
            const selectedValue = event.target.value;
            updateCaptureType(selectedValue);
            if(safieLiveStreamList !== undefined && safieLiveStreamList.includes(data.camera_name)) {
                data['enable_hw_acceleration'] = true;
                data['video_duration'] = '600';
                data['video_split_mode'] = 'time';
                data['video_file_max_size'] ='50';
                data['periodic_snapshot'] = true;
                data['enable_save_mp4'] = true;
                data['video_fps'] = '15/1';
                Object.keys(cameraOptions).map((i) => { if(cameraOptions[i].value === data.camera_name){
                    swal('Live stream for selected camera name already exists.', {icon: 'error'});
                    return;
                }})
            } else {
                data['enable_save_mp4'] = true;
                data['video_split_mode'] = 'time';
                data['video_duration'] = '600';
                data['periodic_snapshot'] = true
                data['save_interval'] = '120';
                data['periodic_snapshot_resolution'] = '1920x1080';
                data['enable_snapshot_time_overlay'] = false;
                data['save_original_snapshot'] = false;
                data['video_device'] = stackName;
                data['recorder_type'] = 'stream_recorder';
                data['video_file_max_size'] = '50';
                data['video_source_type'] = "SAFIE";
                data['enable_hw_acceleration'] = true;
                data['specify_snapshot_resolution'] = false;
                data['resolution'] = '1280x720';
                data['video_fps'] = '15/1'
                if(Constants.AD_HW_VPU === 'NVIDIA') {
                    data['gpu_affinity'] = 'no_affinity';
                }
                delete data.image_pull_min;
                delete data.image_pull_sec;
                delete data.image_pull_hrs;
                delete data.still_capture_api;
                props.setRTSPToggleState(false);
            }
            } else {
                if(event.target.name === 'camera_name' && data.capture_type === 'live_streaming') {
                    if(safieLiveStreamList !== undefined && safieLiveStreamList.includes(data.camera_name)) {
                        Object.keys(cameraOptions).map((i) => { if(cameraOptions[i].value === data.camera_name){
                            swal('Live stream for selected camera name already exists.', {icon: 'error'});
                            return;
                        }})
                    }
            }
        } 
        if(event.target.name === 'camera_name') {
            props.changeCameraName(event.target.value);
        }
        if(event.target.name === 'video_device'  && !isViewPage) {
            getVideoDeviceData(event.target.value);
            props.selectedVideoDevice(event.target.value);
        } else if(isViewPage && event.target.name === 'video_device') {
            props.selectedVideoDevice(event.target.value);
            axios.get(Constants.EDGE_API_ENDPOINT + "/devices/video/" + event.target.value)
            .then(res => {
                if(res.data["data"]){
                let videoInfoData = res.data["data"];
                let videoSourcesData = Object.keys(videoInfoData);
                let resolution = videoInfoData[videoSourcesData[0]];
                let resolutionData =  Object.keys(resolution);
                let frameRatesData = videoInfoData[videoSourcesData[0]][resolutionData[0]]
                addFrameRate(frameRatesData);
                setVideoDeviceInfo(videoInfoData)
                setVideoSourceType(videoSourcesData)
                setResolutions(Object.keys(resolution));
                data.resolution = resolutionData[0];
                data.video_source_type = videoSourcesData[0];
                data.video_fps = videoInfoData[videoSourcesData[0]][resolutionData[0]][0];
                setFrameRates(frameRatesData)
                }
            })
            props.selectedVideoDevice(event.target.value);
        }
        setInputs(data);
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const handleRTMPStreamKeyChange = (event) => {
        event.persist();
        if(event.target.name === "rtmp_stream_key_type"){
            let data = {...inputs, [event.target.name]: event.target.value};
            data = {...data, ["rtmp_stream_key"]: ""};
            setInputs(data);
        } else if(event.target.name === "rtmp_stream_key"){
            event.target.value = event.target.value.replace(" ", "");
            let data = {...inputs, [event.target.name]: event.target.value};
            setInputs(data);
        }
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const handleRadioOnChange = (event) => {
        event.persist();
        let data = inputs;
        if(event.target.id === "rtmp_server_internal"){
            data["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER;
            if(data["rtmp_url_type"] == undefined){
                data["rtmp_url_type"] = "mDNS";
            }
        } else if(event.target.id === "rtmp_server_external"){
            data["rtmp_source_url"] = "rtmp://";
        } else if(event.target.id === 'gpu_affinity' && event.target.value === 'user' && inputs.gpu_device === undefined) {
            data.gpu_device = gpuList[0].value;
        } else if(event.target.id === 'gpu_affinity' && event.target.value === 'system' && inputs.gpu_device === undefined) {
            delete data.gpu_device;
        }
        data[event.target.name] = event.target.value;
        setInputs(data);
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const videoInfoOnChange = (event) => {
        event.persist();
        let data = {...inputs, [event.target.name]: event.target.value};
        setInputs(data);
        if(event.target.name === "video_source_type"){
            videoSourceTypeOnChange(event.target.value, data)
        } else if(event.target.name === "resolution"){
            resolutionOnChange(event.target.value, data)
        }
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    function videoSourceTypeOnChange(videoSource, data){
        let resolutionsData = Object.keys(videoDeviceInfo[videoSource])
        let frameRatesData = videoDeviceInfo[videoSource][resolutionsData[0]]
        addFrameRate(frameRatesData);
        setResolutions(resolutionsData)
        setFrameRates(frameRatesData)
        if(frameRatesData.length === 1){
            //setInputsValue(frameRatesData,"video_fps", data)
            data["video_fps"] = frameRatesData[0]
        }
        if(resolutionsData.length === 1){
            //setInputsValue(resolutionsData, "resolution", data)
            data["resolution"] = resolutionsData[0]
        }
        setInputs(data);
    }

    function resolutionOnChange(resolution, data){
        let frameRatesData = videoDeviceInfo[inputs["video_source_type"]][resolution]
        addFrameRate(frameRatesData);
        setFrameRates(frameRatesData)
         if(frameRatesData.length === 1){
            setInputsValue(frameRatesData,"video_fps", data)
        }
    }

    function setInputsValue(value, type, data){
        data[type] = value[0]
        setInputs(data);
    }

    function addFrameRate (frameRatesData){
        if(frameRatesData !== undefined) {
            for (const[index,value] of frameRatesData.entries()){
                if((frameRatesData[index].includes("/1")===true)){
                    frameRatesData[0] = frameRatesData[0].replace("/1","fps");
                }
                if(frameRatesData[index].includes("fps")===false){
                    if(frameRatesData[index] !== "auto"){
                        frameRatesData[index] = frameRatesData[index]+"fps";
                    }
                }
            }
        }
    }

    const handleNumberOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            if(event.target.name === "video_file_max_size") {
                setInputs({...inputs, [event.target.name]: event.target.value});
                setServiceChange({...serviceChange, ["recorder"]: true});
            } else {
                setInputs({...inputs, [event.target.name]: event.target.value});
                setServiceChange({...serviceChange, ["recorder"]: true});
            }
        } else {
            if(event.target.value === "" || event.target.value === "0"){
                if(event.target.name === "rtsp_source_buffer_time"){
                    // setInputs({...inputs, [event.target.name]: event.target.value});
                    setInputs({...inputs, [event.target.name]: ""});
                } else {
                    setInputs({...inputs, [event.target.name]: ""});
                }
                setServiceChange({...serviceChange, ["recorder"]: true});
            }
        }
    }

    const handleIpAddrChange = (event) =>{
        event.persist();
        event.target.value = event.target.value.replace(/[^.\d]/g, '');
        setInputs({...inputs, [event.target.name]: event.target.value});
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const handlePacketDelay = (event) =>{
        event.persist();
        if(event.target.id === 'maximum_packet_delay' === ''){
            swal({text: "Please enter maximum packet delay in sec(s)." , icon: "error"});
        }else if((Number(event.target.value) >= 1 && Number(event.target.value) <= 9999) || event.target.value === ''){
        event.target.value = event.target.value.replace(/[^.\d]/g, '');
        event.target.value = event.target.value.replace(".", "");
        setInputs({...inputs, [event.target.name]: event.target.value});
        setServiceChange({...serviceChange, ["recorder"]: true});
    }
}

    const handleToggleChange = (value, event) => {
        if(!readOnly && !noVideoDevice){
            if(inputs.enable_save_mp4 === false && event.target.id === 'enable_mp4_time_overlay'){
              return;
            }
            if(inputs.periodic_snapshot === false && event.target.id === 'enable_snapshot_time_overlay'){
                return;
              }
            let data = {...inputs, [event.target.id]: value};
            if(event.target.id === 'enable_save_mp4' && value === false){
              data['enable_mp4_time_overlay'] = false;
            }
            if(event.target.id === 'periodic_snapshot' && value === false){
                data['enable_snapshot_time_overlay'] = false;
              }
            setInputs(data);
            setServiceChange({...serviceChange, ["recorder"]: true});
        }
    };

    const handleToggleChangeSafie = (value, event) => {
        if(!readOnly){
            let data = {...inputs, [event.target.id]: value};
            setInputs(data);
            setServiceChange({...serviceChange, ["recorder"]: true});
        }
    };

    const handleCheckboxOnChange = (event) => {
        if(!readOnly){
          if(event.target.name === "specify_snapshot_resolution" && event.target.checked === false){
             let data = {...inputs, [event.target.name]: event.target.checked};
            //  data["save_original_snapshot"] = false;
             setInputs(data);
          } else {
            setInputs({...inputs, [event.target.name]: event.target.checked});
          }
          setServiceChange({...serviceChange, ["recorder"]: true});
        }
    };

    const editRecorder = (event) => {
        setReadOnly(false);
    }

    useEffect(() =>{
        if(source === 'USB') {
            setInfoOnDeviceChange();
        }
    },[]);

    function updateRecorder(recorderJson){
        recorderJson["enabled"] = stackStatus;
        //Update Recorder
        axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + recorderJson["service_name"] , recorderJson)
            .then(res => {
                setTimeout(function(){ window.location.reload() }, 500)
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal({text:"Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });
    }

    function isIp(value) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value))
        {
            return true;
        } else {
            return false;
        }
    }

    const cancelRecorder = (event) => {
        window.location.reload()
    }

      useEffect(() => {
        var sizeInMB = (videoSize / (1024*1024)).toFixed(2);
        let num = Math.floor(sizeInMB);
        let finalSize = num * (1 - 60 / 100);
        setSplitIntervalMaxVal(Math.floor(finalSize));
      },[videoSize]);

    const saveRecorder = (event) => {
        var recorderJson = Object.assign({}, inputs);
        if(recorderJson.gpu_affinity === 'no_affinity' || recorderJson.gpu_affinity === 'system') {
            delete recorderJson.gpu_device;
        }
        if(!recorderJson.enable_hw_acceleration) {
            delete recorderJson.gpu_device;
            delete recorderJson.gpu_affinity;
        }       
        if(recorderJson["enable_save_mp4"] === true){
          if(recorderJson["video_split_mode"] === 'size'){
            if(recorderJson['video_file_max_size'] > splitIntervalMaxVal) {
              swal({text:`Please enter split interval value <= ${splitIntervalMaxVal}MiB`, icon: 'error'});
              return;
            } else if(recorderJson['video_file_max_size'] === "") {
              swal({text:"Please enter mp4 split interval value in MiB", icon: 'error'})
              return;
            } else {
                recorderJson['video_file_max_size'] = recorderJson['video_file_max_size']*(1024*1024) + "";
            }
          } else if(recorderJson["video_split_mode"] === 'time'){
            if(recorderJson["video_duration"] == undefined || recorderJson["video_duration"] ==""){
              swal({text:"Please enter mp4 split interval value in sec(s)", icon: 'error'})
              return;
            } if(recorderJson['video_file_max_size'] === '') {
                recorderJson['video_file_max_size'] = 50*(1024*1024) + "";
            } else {
                recorderJson['video_file_max_size'] = recorderJson['video_file_max_size']*(1024*1024) + "";
            }
          }
        } else {
            recorderJson['video_file_max_size'] = recorderJson['video_file_max_size']*(1024*1024) + "";
        }
        if(recorderJson['save_interval'] === "") {
            swal({text:"Please enter save interval value in sec(s)", icon: 'error'})
            return;
        }
        if(recorderJson["video_duration"] < parseInt('5')) {
            swal({text: "Please enter interval >= 5 sec" , icon: "error"});
            return;
        }
        if(source === "RTMP"){
            let streamKey = recorderJson ["rtmp_stream_key"];
            let bufferTime = recorderJson["rtmp_source_buffer_time"];
            if((streamKey === "" || streamKey === undefined) && recorderJson ["rtmp_stream_key_type"] === "custom_rtmp_stream_key"){
                swal({text:"Please enter custom stream/key", icon: 'error'})
                return;
            }
            if((bufferTime === "" || bufferTime === undefined)){
                swal({text:"Please enter buffer time", icon: 'error'})
                return;
            }
            if(isEmpty(recorderJson["rtmp_source_url"])){
                swal({text:"Please enter server url", icon: 'error'});
                return;
            }
            var rtmpUrlFormat = /^(rtmp?):?\/\/./;
            if (!rtmpUrlFormat.test(recorderJson["rtmp_source_url"])) {
                swal({text:"Server url is invalid", icon: 'error'});
                return;
            }
            if(recorderJson["rtmp_server_type"] === "internal"){
                if(recorderJson["rtmp_stream_key"] === ""){
                    recorderJson["rtmp_stream_key"] = stackName;
                }
                if(recorderJson["rtmp_url_type"] === "mDNS"){
                    recorderJson["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", mDnsName)
                } else {
                    recorderJson["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", deviceIP)
                }
            }
        } else if(source === "RTSP"){
            var rtspUrlFormat = /^(rtsp|rtspt?):?\/\// 
            if (!rtspUrlFormat.test(recorderJson["rtsp_source_url"])) {
                swal({text:"Invalid RTSP URL", icon: 'error'})
                return;
            }
            if(recorderJson["rtsp_source_buffer_time"] === undefined || recorderJson["rtsp_source_buffer_time"] ===""){
                swal({text:"Please enter buffer time", icon: 'error'})
                return;
            }

            if (recorderJson["maximum_packet_delay_type"]) {
            }
            if (recorderJson["maximum_packet_delay_type"] === "auto") {
                const data = recorderJson["rtsp_source_buffer_time"]
                recorderJson["maximum_packet_delay"] = "auto"
            }

            let maximum_packet_delay = recorderJson["maximum_packet_delay"];
            if (( maximum_packet_delay === '' || maximum_packet_delay <= 0)) {
                swal({ text: "Please enter maximum packet delay in sec(s).", icon: 'error' })
                return;
            }

        } else if(source === "HTTP"){
            var rtspUrlFormat = /^(http|https?):?\/\// 
            if ((recorderJson["http_source_url"] === '')) {
                swal({text:"Please enter HTTP URL", icon: 'error'})
                return;
            }
            if (!rtspUrlFormat.test(recorderJson["http_source_url"])) {
                swal({text:"Invalid HTTP URL", icon: 'error'})
                return;
            }

        } else if(source === "USB"){
            if (recorderJson["video_fps"] === "auto") {
                recorderJson["video_fps"] =  recorderJson["video_fps"].replace("fps", "");
            } else {
                recorderJson["video_fps"] =  recorderJson["video_fps"].replace("fps", "/1");
            }

            var resolution = recorderJson["resolution"];
            var regEx = /\((.*?)\)/
            var resolutionList = regEx.exec(resolution)
            var arr = resolutionList[1].split("x");
            recorderJson["video_width"] = arr[0];
            recorderJson["video_height"] = arr[1];
            if(Constants.AD_HW_VPU === 'NVIDIA' && recorderJson.video_source_type === 'UVC_H264') {
                delete recorderJson.gpu_device;
                delete recorderJson.gpu_affinity;
            }
            delete recorderJson["resolution"];
        } else if(source === "RTP" || source === "MLIT") {
            if(Constants.AD_HW_VPU !== 'NVIDIA' && Constants.AD_HW_VPU !== 'MMAL' ){
            if(inputs.transport_encapsulation==="MPEG2PS"){
                recorderJson['enable_hw_acceleration'] = false;
            }
        }
            if(recorderJson["enable_video_multicast"] === true){
                if(isEmpty(recorderJson["video_multicast_address"])){
                    swal({text:"Please enter video multicast address", icon: 'error'})
                    return;
                }
                if(!isIp(recorderJson["video_multicast_address"])){
                    swal({text:"Please enter valid video multicast address", icon: 'error'})
                    return;
                }

                if(isEmptyNumber(recorderJson["video_port_rtp"])){
                    swal({text:"Please enter video RTP port", icon: 'error'})
                    return;
                } 

                if((isEmptyNumber(recorderJson["maximum_packet_delay"])) || (isEmpty(recorderJson["maximum_packet_delay"])) ){
                    swal({text:"Please enter maximum packet delay in sec(s).", icon: 'error'})
                    return;
                }
                if((recorderJson["maximum_packet_delay"] <=0) ){
                    swal({text:"Please enter maximum packet delay >=1 (sec).", icon: 'error'})
                    return;
                }
                if(recorderJson["transport_encapsulation"] === 'auto'){
                    delete recorderJson["RTP_TS_PROG_DECODE"];
                    delete recorderJson["video_interlace_mode"];
                    delete recorderJson["video_payload_type"];
                    delete recorderJson["video_codec"];
                }
                if((recorderJson["transport_encapsulation"] === "MPEG2TTS" || recorderJson["transport_encapsulation"] === "MPEG2TS") && recorderJson["video_interlace_mode"] === "mixed" ){
                    recorderJson["RTP_TS_PROG_DECODE"] = true;
                }
                if(recorderJson["transport_encapsulation"] === "MPEG2PS"){
                    delete recorderJson["video_interlace_mode"];
                    recorderJson["RTP_TS_PROG_DECODE"] = true;
                }
            } 
            
            
            if(isEmptyNumber(recorderJson["video_port_rtcp"]) && recorderJson["enable_video_rtcp_muxed"] === false){
                swal({text:"Please enter video RTCP port", icon: 'error'})
                return;
            }
            if(recorderJson["enable_audio"] === true){
                if(recorderJson["enable_audio_multicast"] === true){
                    if(isEmpty(recorderJson["audio_multicast_address"])){
                        swal({text:"Please enter audio multicast address", icon: 'error'})
                        return; 
                        }
                    if(!isIp(recorderJson["audio_multicast_address"])){
                        swal({text:"Please enter valid audio multicast address", icon: 'error'})
                        return;
                    }
                }
                if(isEmpty(recorderJson["audio_port_rtp"])){
                    swal({text:"Please enter audio port", icon: 'error'})
                    return;
                }
                if(isEmpty(recorderJson["audio_port_rtcp"]) && recorderJson["enable_audio_rtcp_muxed"] === false){
                    swal({text:"Please enter audio port", icon: 'error'})
                    return;
                }
            }
            if(Constants.AD_HW_VPU === 'NVIDIA' && recorderJson.transport_encapsulation === 'H264' || recorderJson.transport_encapsulation === 'MPEG2TS' || recorderJson.transport_encapsulation === 'MPEG2TTS') {
                delete recorderJson.gpu_device;
                delete recorderJson.gpu_affinity;
            }

            if(source === 'MLIT' && recorderJson.recorder_type === 'mlit_recorder') {
                recorderJson.camera_type = 'RTP'
            }
            let rtpPorts = []
            let video_port = recorderJson["video_port_rtp"]
            let video_port_rtcp = recorderJson["video_port_rtcp"]
            let audio_port = recorderJson["audio_port_rtp"]
            let audio_port_rtcp = recorderJson["audio_port_rtcp"]
            let inputPorts = [video_port, video_port_rtcp, audio_port, audio_port_rtcp]
            var port
            for (port of inputPorts) {
                if (port.length > 0){
                    rtpPorts.push(port)
                }
            }

            let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
            if (findDuplicates(rtpPorts).length > 0) {
                swal({text:"Duplicate ports entries not allowed", icon: 'error'});
                return;
            }
        }else if(source === "FTP"){
            let port = recorderJson ["port"]
            if((port === "" || port === undefined) && recorderJson ["server_type"] === "external"){
                swal({text:"Please enter port", icon: 'error'})
                return;
            }
            let source_directory = recorderJson ["source_directory"];
            if((source_directory === "" || source_directory === undefined) && recorderJson ["source_directory_type"] === "custom_source_directory"){
                swal({text:"Please enter custom source directory", icon: 'error'})
                return;
            }
            let date_time_format = recorderJson ["date_time_format"]
            if((date_time_format === "" || date_time_format === undefined) && recorderJson ["ingest_time"] === "File Name"){
                swal({text:"Please enter date time format", icon: 'error'})
                return;
            }
            if(recorderJson ["server_type"] === "internal"){
                if(recorderJson ["url_type"] === "mDNS"){
                    let mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
                    recorderJson ["source_url"] = Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", mDnsName)
                } else {
                    let deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");
                    recorderJson ["source_url"] = Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", deviceIP)
                }
                if(recorderJson ["source_directory"] === ""){
                    recorderJson ["source_directory"] = stackName;
                }
            } else {
                delete recorderJson ["source_directory_type"];
                delete recorderJson ["url_type"];
            }

            if(recorderJson["enable_user_login"] === true){
                if(isEmpty(recorderJson["username"])){
                    swal({text:`Please enter username`, icon: 'error'})
                    return;
                }
                if(isEmpty(recorderJson["password"])){
                    swal({text:`Please enter password`, icon: 'error'})
                    return;
                }
            }
        } else if(source === "SAFIE") {
            props.triggerSave(true);
            if(recorderJson["capture_type"] === 'still_capture') {
                if(recorderJson["still_capture_api"] === 'live') {
                    if(recorderJson["image_pull_min"] === '00' && recorderJson["image_pull_sec"] === '00' && recorderJson["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    } else if(recorderJson["image_pull_min"] === '00' && recorderJson["image_pull_sec"] < '30' && (recorderJson["image_pull_hrs"] === '00' || recorderJson["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    }
                } else if(recorderJson["still_capture_api"] === 'thumbnail') {
                    if(recorderJson["image_pull_min"] === '00' && recorderJson["image_pull_sec"] === '00' && recorderJson["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    } else if(recorderJson["image_pull_min"] === '00' && recorderJson["image_pull_sec"] < '30' && (recorderJson["image_pull_hrs"] === '00' || recorderJson["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    }
                } else if(recorderJson["still_capture_api"] === 'still') {
                    if(recorderJson["image_pull_min"] === '00' && recorderJson["image_pull_sec"] === '00' && recorderJson["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 1 min" , icon: "error"});
                        return;
                    } else if(recorderJson["image_pull_min"] === '00' && recorderJson["image_pull_sec"] !== '00' && (recorderJson["image_pull_hrs"] === '00' || recorderJson["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 1 min" , icon: "error"});
                        return;
                    }
                }
            }
            if(captureType === 'still_capture'){
                recorderJson["frame_emitter_enabled"] = false;
                recorderJson["frame_emitter_conn_ep"] = [];
            }
        }
        if(serviceChange.recorder === true){
            updateRecorder(recorderJson);
        } else {
            setReadOnly(true);
        }
    }

    const clickToCopy = (event) => {
        let copyElement = document.getElementsByName("rtmp_source_url")[0];
        copyElement.select();
        document.execCommand("copy");
        copyElement.blur();
    }

    const clickToCopyFtpUrl= (event) => {
        let copyElement = document.getElementsByName("server_url")[0];
        copyElement.select();
        document.execCommand("copy");
        copyElement.blur();
    }

    const clickToCopySourceDir= (event) => {
        let copyElement = document.getElementById("source_directory");
        copyElement.select();
        document.execCommand("copy");
        copyElement.blur();
    }

    const handleOnLabelChange = (value) => {
        setInputs({...inputs, ["rtmp_url_type"]: value});
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const handleOnSrcDirLabelChange = (value) => {
        setInputs({...inputs, ["url_type"]: value});
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const handleSourceDirChange = (event) => {
        event.persist();
        if(event.target.name === "source_directory_type"){
            let data = {...inputs, [event.target.name]: event.target.value};
            data = {...data, ["source_directory"]: ""};
            setInputs(data);
        } else if(event.target.name === "source_directory"){
            event.target.value = event.target.value.replace(" ", "");
            let data = {...inputs, [event.target.name]: event.target.value};
            setInputs(data);
        }

        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const handleSourcePacketDelay = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(event.target.name === "maximum_packet_delay_type"){
            let data = {...inputs, [event.target.name]: event.target.value};
            data = {...data};
            setInputs(data);
        }else if((Number(event.target.value) >= 1 && Number(event.target.value) <= 9999) || event.target.value === '' && event.target.name === "maximum_packet_delay"){
            event.target.value = event.target.value.replace(" ", "");
            event.target.value = event.target.value.replace(".", "");
            event.target.value = event.target.value.replace(/[^.\d]/g, '');
            let data = {...inputs, [event.target.name]: event.target.value};
            setInputs(data);
        }else if((event.target.value === "maximum_packet_delay")){

        }
        setServiceChange({...serviceChange, ["recorder"]: true});
    }

    const handleDateOnBlur = (event) =>{
        if(event.target.value === ""){
            swal({text:"Please Enter Date Format", icon: 'error'});
            return;
        } 
    }

    const clickToCopyRTMPStreamKey= (event) => {
        let copyElement = document.getElementById("rtmp_stream_key");
        copyElement.select();
        document.execCommand("copy");
        copyElement.blur();
    }

    useEffect(() => {
        if(source === "FTP" && props.readOnly === false){
            axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/internal_ftp')
            .then(res => {
                let ftpData = res.data['data'];
                if(Object.keys(ftpData).length > 0){
                    let data = inputs;
                    Object.keys(ftpData).map(key => {
                    if(key === "enable_user_login" || key === "username" ||  key === "enable_anonymous_access"){ 
                        data[key] = ftpData[key];
                    }});
                    setInputs(data);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }, [inputs !== undefined ])

    const incrementRTCPort = (event) => {
        if(!isEmpty(event.target.value)){
            if(event.target.name === "video_port_rtp" && isEmpty(inputs["video_port_rtcp"])){ 
                let val = parseInt(event.target.value, 0) + 1;
                val = "" + val;
                setInputs({...inputs, ["video_port_rtcp"] : val});
            }
            if(event.target.name === "audio_port_rtp" && isEmpty(inputs["audio_port_rtcp"])){
                let val = parseInt(event.target.value, 0) + 1;
                val = "" + val;
                setInputs({...inputs, ["audio_port_rtcp"] : val});
            }
        }
    }

    const getMp4Details = () => {
        setModalOpen(true);
    }

    const closePopup = (event) => {
        setModalOpen(false);
      };


    useEffect(() => {
        if(Constants.AD_HW_VPU === 'NVIDIA' && (source === 'RTSP' || source === "HTTP" || source === 'RTP' || source === 'RTMP' || source === 'USB')) {
            axios.get(Constants.EDGE_API_ENDPOINT + '/devices/gpus/all')
            .then(res => {
                let availableGPU = [];
                Object.keys(res.data.data.all_devices).map((i)=>{
                    let options = {};
                    if(res.data.data.all_devices[i].idx === 'auto') {
                        options.name='auto';
                        options.value='auto';
                        availableGPU.push(options);
                    } else {
                        let busId = res.data.data.all_devices[i].bus_id.split(':');
                        let gpuName = res.data.data.all_devices[i].name .split(' ');
                        options.name = res.data.data.all_devices[i].idx + ' - ' + gpuName[1] + ' ' + gpuName[2] + ' Bus ' + busId[1] + ':' + busId[2];
                        options.value = res.data.data.all_devices[i].idx;
                        availableGPU.push(options);
                    }
                    return i;
                });
                if(availableGPU.length === 0) {
                    setInputs({...inputs, ["enable_hw_acceleration"] : false})
                }
                setGpuList(availableGPU);
            }).catch(error => {
                console.log(error);
            });
        }
    },[]);

    useEffect(() => {
        if(source === "USB"){
            axios.get(Constants.EDGE_API_ENDPOINT + '/devices/videos')
                .then(res => {
                    let respData = res.data["data"];
                    if(respData){
                        if(respData["all_devices"].length > 0) {
                            setVideoDevicesAll(respData["all_devices"]);
                        }
                        if(respData["available_devices"].length > 0) {
                            setVideoDevices((respData["available_devices"]).sort());
                            if(!isViewPage) {
                                setInputs(inputs => ({...inputs, ["video_device"]: respData["available_devices"][0].name}));
                                getVideoDeviceData(respData["available_devices"][0].name);
                                props.selectedVideoDevice(respData["available_devices"][0].name);
                            }
                        } else {
                            if(!isViewPage) {
                                let noVideoDevice = [{"name": "No Video Device Available"}];
                                setVideoDevices(noVideoDevice);
                                setNoVideoDevice(true);
                            }
                        }
                    }
                })
                .catch(error => {
                    setVideoDevices([]);
                    setInputs({});
                });
        }
    },[]);

    function getVideoDeviceData(deviceName){
        axios.get(Constants.EDGE_API_ENDPOINT + "/devices/video/" + deviceName)
            .then(res => {
                if(res.data["data"]){
                    let data = {...inputs, ["video_device"]: deviceName};
                    setInputs(data);
                    setViedosInformation(res.data["data"]);
                }
            })
    }
    const getColor=()=>{
        if(readOnly && inputs.specify_snapshot_resolution && inputs.periodic_snapshot){
            return 'rgb(41 40 39)';
        }else{
            if(inputs.specify_snapshot_resolution && inputs.periodic_snapshot ===false ){
                return 'rgb(41 40 39)';
            } else if(inputs.specify_snapshot_resolution === false && inputs.periodic_snapshot ===true){
                return 'rgb(135 149 139)';
            } else if(inputs.periodic_snapshot === false && inputs.specify_snapshot_resolution === false){
                return 'rgb(135 149 139)' ;
            }
        }
    }

    return (
            <TabPanel tabId="recordingTab">
            { inputs &&
                <div>
                    <ResponsiveRow>
                    {(source === "RTMP" || source === 'RTP' || source === 'USB' || source === 'RTSP' || source === "HTTP" || source === 'MLIT' || source === 'SAFIE') &&
                        <ResponsiveColumn>
                        {( source === 'RTP') && 
                        <div style={{"display": "flex", 'marginBottom': '15px', 'marginTop': '10px'}}>
                                <TextFieldLabel style={{'marginTop': '3px'}}>Transport Encapsulation</TextFieldLabel>
                                <SelectField name="transport_encapsulation" style={{"margin-left": "28px"}} value={inputs.transport_encapsulation} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.RTP_TRANSPORT_ENCAPSULATION_TYPE}/>
                                </SelectField>
                            </div>}
                            {( source === 'MLIT') && 
                            <div style={{"display": "flex", 'marginBottom': '15px', 'marginTop': '10px'}}>
                                    <TextFieldLabel style={{'marginTop': '3px'}}>Stream Type</TextFieldLabel>
                                    <SelectField name="transport_encapsulation" style={{"margin-left": "10px"}} value={inputs.transport_encapsulation} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.MLIT_STREAM_TYPE}/>
                                    </SelectField>
                                </div>}
                            {( source === 'USB') && 
                            <>
                            <div style={{"display": "flex"}}>
                            <TextFieldLabel1 style={{'marginTop': '3px', 'marginLeft': '3px'}}>Video Device</TextFieldLabel1>
                                <SelectField style={{'width':'100%'}} name="video_device" value={inputs.video_device} onChange={handleOnChange} disabled={isViewPage ? readOnly : noVideoDevice ? true : false}>
                                    {!isViewPage ? 
                                    <>
                                        { videoDevices && videoDevices.map((item) => {
                                            let videoDescription = item.description ? ' (' + item.description + ')' : '';
                                            return (<option key={item.name} value={item.name}>{item.name + videoDescription}</option>)
                                            }
                                        )}
                                    </> : 
                                    <>
                                        {videoDevicesAll !== undefined && videoDevicesAll.map((item) => {
                                            if(item.name === devcieName){
                                                let videoDescription = item.description ? ' (' + item.description + ')' : '';
                                                return (<option value={devcieName}>{devcieName + videoDescription}</option>)
                                            }
                                            })
                                        }
                                        { videoDevices !== undefined && videoDevices.map((item) => {
                                            let videoDescription = item.name ? item.name + ' (' + item.description + ')' : '';
                                            return (<option value={item.name}>{videoDescription}</option>)
                                            })
                                        }
                                    </>
                                    }
                                
                                </SelectField>
                                </div>
                                <div style={{"display": "flex", 'marginBottom': '15px', 'marginTop': '10px'}}>
                                    <TextFieldLabel style={{'marginTop': '3px', 'marginLeft': '3px'}}>Video Source Type</TextFieldLabel>
                                    <SelectField name="video_source_type" style={{ "margin-left": "8px" }} value={inputs.video_source_type} disabled={isViewPage ? readOnly : noVideoDevice ? true : false} onChange={videoInfoOnChange}>
                                        <SelectOptions items={videoSourceType}/>
                                    </SelectField>
                                </div>
                            </>}
                    {(source === "RTMP" || source === 'RTP' || source === 'USB' || source === 'RTSP' || source === "HTTP" || source === 'MLIT' || source === 'SAFIE' && inputs.capture_type === 'live_streaming') &&   
                        <fieldset style={{"paddingTop": "5px", 'paddingBottom': Constants.AD_HW_VPU === 'NVIDIA' ? ((inputs.enable_hw_acceleration ? '20px' :'10px') || (((source === 'RTP' && inputs.transport_encapsulation === 'MPEG2PS') || (source === 'RTMP') || (source === 'USB' && (inputs.video_source_type === 'UVC_I420' || inputs.video_source_type === 'UVC_MJPG')) ) ? '20px' : '10px') ): '10px'}}>
                            <legend>Hardware Acceleration</legend>

                            { Constants.AD_HW_VPU !== 'NVIDIA' && Constants.AD_HW_VPU !== 'MMAL' && inputs.transport_encapsulation == 'MPEG2PS' &&
                               <SelectionControl id="enable_hw_acceleration" type="switch" isabled label={"Disabled"} name="enable_hw_acceleration" labelBefore="true" checked={false} disabled={!readOnly || readOnly} />
                            }

                            {Constants.AD_HW_VPU !== 'NVIDIA' && (inputs.transport_encapsulation !== 'MPEG2PS') && <SelectionControl id="enable_hw_acceleration" type="switch" label={inputs.enable_hw_acceleration ? "Enabled" : "Disabled"} name="enable_hw_acceleration" labelBefore="true" checked={inputs.enable_hw_acceleration || false} onChange={handleToggleChange} />}
                            {Constants.AD_HW_VPU === 'NVIDIA' && gpuList ?
                                <>{(source === 'RTP' && inputs.transport_encapsulation === 'MPEG2PS') || source === "RTMP" || (source === 'USB' && (inputs.video_source_type === 'UVC_I420' || inputs.video_source_type === 'UVC_MJPG')) ? 
                                <>
                                    <SelectionControl id="enable_hw_acceleration" type="switch" label={inputs.enable_hw_acceleration ? "Enabled" : "Disabled"} name="enable_hw_acceleration" labelBefore="true" checked={inputs.enable_hw_acceleration || false} onChange={handleToggleChange} disabled={gpuList.length > 0 ? false : true} style={{'marginBottom': inputs.enable_hw_acceleration ? '10px' : '0', 'marginLeft': '2px'}} />
                                        {inputs.enable_hw_acceleration && <>
                                        <Label style={{'marginLeft': '2px'}}>Video Codec GPU Affinity</Label>
                                        <div style={{'marginTop': '5px'}}>
                                            <input type="radio" id="gpu_affinity" name="gpu_affinity" value="no_affinity" checked={inputs.gpu_affinity === "no_affinity" || false} onChange={handleRadioOnChange} disabled={gpuList.length > 0 ? readOnly : true} /><label>No affinity</label>{(window.innerWidth === 1366 || isMobileBrowser)&& <br />}
                                            <input type="radio" id="gpu_affinity" name="gpu_affinity" value="system" checked={inputs.gpu_affinity === "system" || false} style={{"marginLeft": window.innerWidth === 1920 ? "50px" : ''}} onChange={handleRadioOnChange} disabled={gpuList.length > 0 ? readOnly : true} /><label>Assign one by the system</label>{(window.innerWidth === 1366 || isMobileBrowser)&& <br />}
                                            <input type="radio" id="gpu_affinity" name="gpu_affinity" value="user" checked={inputs.gpu_affinity === "user" || false} style={{"marginLeft": window.innerWidth === 1920 ? "50px" : ''}} onChange={handleRadioOnChange} disabled={gpuList.length > 0 ? readOnly : true} /><label>Assign one by the user</label>{(window.innerWidth === 1366 || isMobileBrowser)&& <br />}
                                        </div>
                                        {inputs.gpu_affinity === 'user' && <SelectField name="gpu_device" id='gpu_device' value={inputs.gpu_device} onChange={handleOnChange} style={{'marginTop': '20px'}} disabled={gpuList.length > 0 ? readOnly : true}>
                                        {gpuList.length > 0 ? <SelectOptions items={gpuList}/> : <option>No GPU(s) Available</option>}
                                        </SelectField>}</>}
                                </> : <SelectionControl id="enable_hw_acceleration" type="switch" label={inputs.enable_hw_acceleration ? "Enabled" : "Disabled"} name="enable_hw_acceleration" labelBefore="true" checked={inputs.enable_hw_acceleration || false} onChange={handleToggleChange} style={{'marginBottom':'0', 'marginLeft': '2px'}}/>}  
                                </> : <div />}
                        </fieldset>}
                       { source === "SAFIE" &&
                        <>
                        {configure && !safieLoading && !cameraOptions && !safieDeviceLoading && !inputs.camera_name &&
                            <Row>
                            <Label>Camera Name (Camera ID)</Label>
                            <SelectField name="camera_name" id= "camera_name" value={inputs.camera_name} disabled={true}>
                                <option value='empty'>No Safie devices found</option>
                            </SelectField>
                            <Label style={{'marginTop':'15px'}}>Capture Type</Label>
                            <SelectField name="capture_type" value={inputs.capture_type} disabled={true}>
                                <option value='still_capture'>Still capture</option>
                                <option value='live_streaming' disabled={true}>Live stream</option>
                            </SelectField>
                            {inputs.capture_type === 'still_capture' && 
                            <>
                            <Label style={{'marginTop':'15px'}}>Image API Type</Label>
                            <SelectField name="still_capture_api" value={inputs.still_capture_api} disabled={true} style={{'height': '40px'}}>
                                <option value='still'>Still image capture (スチルキャプチャ)</option>
                                <option value='live'>Static images from movie files (画像取得)</option>
                                <option value='thumbnail'>Thumbnail from live streaming (サムネイル取得)</option>
                            </SelectField>
                            </>}
                            {inputs.capture_type === 'still_capture' &&
                                <div style={{"padding": "15px 0px 0px 0px"}}>
                                    <fieldset>
                                        <legend>Image Pull Interval</legend>
                                        <div style={{display: 'flex', alignItems: 'baseline', gap: '15px 10px', flexWrap: 'wrap'}}>
                                            <div>
                                                <LabelHour>Hour</LabelHour>
                                                <SelectField id="image_pull_hrs" name="image_pull_hrs" className="schedule-hours" disabled={true} value={inputs.image_pull_hrs} onChange={handleOnChange}>
                                                    <SelectOptions items={Constants.HOURS}/>
                                                </SelectField>
                                            </div>
                                            <div>
                                                <Label>Minute</Label>
                                                <SelectField id="image_pull_min" name="image_pull_min" className="schedule-minutes" disabled={true} value={inputs.image_pull_min} onChange={handleOnChange}>
                                                    <SelectOptions items={Constants.MINUTES}/>
                                                </SelectField>
                                            </div>
                                            <div>
                                                <Label>Second</Label>
                                                <SelectField id="image_pull_sec" name="image_pull_sec" className="schedule-seconds" disabled={true} value={inputs.image_pull_sec} onChange={handleOnChange}>
                                                    <SelectOptions items={Constants.MINUTES}/>
                                                </SelectField>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>}
                            </Row>}
                            {configure && !cameraOptions && safieDeviceLoading && !inputs.camera_name &&
                            <Row>
                            <Label>Camera Name (Camera ID)</Label>
                            <SelectField name="camera_name" id= "camera_name" value={inputs.camera_name}>
                                <option value='empty'>No Safie devices found</option>
                            </SelectField>
                            <Label style={{'marginTop':'15px'}}>Capture Type</Label>
                            <SelectField name="capture_type" value={inputs.capture_type}>
                                <option value='still_capture'>Still capture</option>
                                <option value='live_streaming'>Live stream</option>
                            </SelectField>
                            {inputs.capture_type === 'still_capture' && 
                            <>
                            <Label style={{'marginTop':'15px'}}>Image API Type</Label>
                            <SelectField name="still_capture_api" value={inputs.still_capture_api} style={{'height': '40px'}}>
                                <option value='still'>Still image capture (スチルキャプチャ)</option>
                                <option value='live'>Static images from movie files (画像取得)</option>
                                <option value='thumbnail'>Thumbnail from live streaming (サムネイル取得)</option>
                            </SelectField>
                            </>}
                            {inputs.capture_type === 'still_capture' &&
                            <div style={{"padding": "15px 0px 0px 0px"}}>
                                <fieldset>
                                    <legend>Image Pull Interval</legend>
                                    <div style={{display: 'flex', alignItems: 'baseline', gap: '15px 10px', flexWrap: 'wrap'}}>
                                        <div>
                                            <LabelHour>Hour</LabelHour>
                                            <SelectField id="image_pull_hrs" name="image_pull_hrs" className="schedule-hours" value={inputs.image_pull_hrs} onChange={handleOnChange}>
                                                <SelectOptions items={Constants.HOURS}/>
                                            </SelectField>
                                        </div>
                                        <div>
                                            <Label>Minute</Label>
                                            <SelectField id="image_pull_min" name="image_pull_min" className="schedule-minutes" value={inputs.image_pull_min} onChange={handleOnChange}>
                                                <SelectOptions items={Constants.MINUTES}/>
                                            </SelectField>
                                        </div>
                                        <div>
                                            <Label>Second</Label>
                                            <SelectField id="image_pull_sec" name="image_pull_sec" className="schedule-seconds" value={inputs.image_pull_sec} onChange={handleOnChange}>
                                                <SelectOptions items={Constants.MINUTES}/>
                                            </SelectField>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>}
                            </Row>}
                        {!configure && isViewPage && !cameraOptions && inputs.camera_name &&
                            <Row>
                            <Label>Camera Name (Camera ID)</Label>
                            <SelectField name="camera_name" id= "camera_name" value={inputs.camera_name} onChange={handleOnChange} disabled={readOnly}>
                                <option value={inputs.camera_name}>{inputs.camera_name}</option>
                            </SelectField>
                            <Label style={{'marginTop':'15px'}}>Capture Type</Label>
                            <SelectField name="capture_type" value={inputs.capture_type} onChange={handleOnChange} disabled={readOnly}>
                                <option value='still_capture'>Still capture</option>
                                <option value='live_streaming'>Live stream</option>
                            </SelectField>
                            {inputs.capture_type === 'still_capture' && 
                            <>
                            <Label style={{'marginTop':'15px'}}>Image API Type</Label>
                            <SelectField name="still_capture_api" value={inputs.still_capture_api} disabled={readOnly} style={{'height': '40px'}}>
                                <option value='still'>Still image capture (スチルキャプチャ)</option>
                                <option value='live'>Static images from movie files (画像取得)</option>
                                <option value='thumbnail'>Thumbnail from live streaming (サムネイル取得)</option>
                            </SelectField>
                            </>}
                            {inputs.capture_type === 'live_streaming' &&
                                    <>
                                        <Label style={{'marginTop':'15px'}}>Framerate of Analysis</Label>
                                        <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={cameraOptions ? true : readOnly}>
                                            <SelectOptions items={Constants.FRAME_RATE_SAFIE}/>
                                        </SelectField>
                                    </>}
                                {inputs.capture_type === 'still_capture' &&
                            <div style={{"padding": "15px 0px 0px 0px"}}>
                                <fieldset>
                                    <legend>Image Pull Interval</legend>
                                    <div style={{display: 'flex', alignItems: 'baseline', gap: '15px 10px', flexWrap: 'wrap'}}>
                                        <div>
                                            <LabelHour>Hour</LabelHour>
                                            <SelectField id="image_pull_hrs" name="image_pull_hrs" className="schedule-hours" disabled={readOnly} value={inputs.image_pull_hrs} onChange={handleOnChange}>
                                                <SelectOptions items={Constants.HOURS}/>
                                            </SelectField>
                                        </div>
                                        <div>
                                            <Label>Minute</Label>
                                            <SelectField id="image_pull_min" name="image_pull_min" className="schedule-minutes" disabled={readOnly} value={inputs.image_pull_min} onChange={handleOnChange}>
                                                <SelectOptions items={Constants.MINUTES}/>
                                            </SelectField>
                                        </div>
                                        <div>
                                            <Label>Second</Label>
                                            <SelectField id="image_pull_sec" name="image_pull_sec" className="schedule-seconds" disabled={readOnly} value={inputs.image_pull_sec} onChange={handleOnChange}>
                                                <SelectOptions items={Constants.MINUTES}/>
                                            </SelectField>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>}
                            </Row>}
                        {configure && cameraOptions ? 
                            <Row>
                                <Label>Camera Name (Camera ID)</Label>
                                <SelectField name="camera_name" id= "camera_name" value={inputs.camera_name} onChange={handleOnChange} disabled={cameraOptions.length <=0 ? true : readOnly}>
                                    <>
                                    {Object.keys(cameraOptions).map((i) => (
                                        <option value={cameraOptions[i].value}>{cameraOptions[i].name} ({cameraOptions[i].value})</option>)
                                    )}
                                    </>
                                </SelectField>
                                { cameraStatus && <div><span style={{'color': 'red'}}>Camera is not connected.</span><br/></div>}
                                <Label style={{'marginTop':'15px'}}>Capture Type</Label>
                                <SelectField name="capture_type" value={inputs.capture_type} onChange={handleOnChange} disabled={cameraOptions.length <=0 ? true : readOnly}>
                                    <option value='still_capture'>Still capture</option>
                                    <option value='live_streaming'>Live stream</option>
                                </SelectField>
                                {inputs.capture_type === 'still_capture' && 
                                <>
                                <Label style={{'marginTop':'15px'}}>Image API Type</Label>
                                <SelectField name="still_capture_api" value={inputs.still_capture_api} onChange={handleOnChange} disabled={cameraOptions.length <=0 ? true : readOnly} style={{'height': '40px'}}>
                                    <option value='still'>Still image capture (スチルキャプチャ)</option>
                                    <option value='live'>Static images from movie files (画像取得)</option>
                                    <option value='thumbnail'>Thumbnail from live streaming (サムネイル取得)</option>
                                </SelectField>
                                </>}
                                {inputs.capture_type === 'live_streaming' &&
                                    <>
                                        <Label style={{'marginTop':'15px'}}>Framerate of Analysis</Label>
                                        <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={cameraOptions.length <=0 ? true : readOnly}>
                                            <SelectOptions items={Constants.FRAME_RATE_SAFIE}/>
                                        </SelectField>
                                    </>}
                                {inputs.capture_type === 'still_capture' &&
                                <div style={{"padding": "15px 0px 0px 0px"}}>
                                    <fieldset>
                                        <legend>Image Pull Interval</legend>
                                        <div style={{display: 'flex', alignItems: 'baseline', gap: '15px 10px', flexWrap: 'wrap'}}>
                                            <div>
                                                <LabelHour>Hour</LabelHour>
                                                <SelectField id="image_pull_hrs" name="image_pull_hrs" className="schedule-hours" disabled={cameraOptions.length <=0 ? true : readOnly} value={inputs.image_pull_hrs} onChange={handleOnChange}>
                                                    <SelectOptions items={Constants.HOURS}/>
                                                </SelectField>
                                            </div>
                                            <div>
                                                <Label>Minute</Label>
                                                <SelectField id="image_pull_min" name="image_pull_min" className="schedule-minutes" disabled={cameraOptions.length <=0 ? true : readOnly} value={inputs.image_pull_min} onChange={handleOnChange}>
                                                    <SelectOptions items={Constants.MINUTES}/>
                                                </SelectField>
                                            </div>
                                            <div>
                                                <Label>Second</Label>
                                                <SelectField id="image_pull_sec" name="image_pull_sec" className="schedule-seconds" disabled={cameraOptions.length <=0 ? true : readOnly} value={inputs.image_pull_sec} onChange={handleOnChange}>
                                                    <SelectOptions items={Constants.MINUTES}/>
                                                </SelectField>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>}
                            </Row> : 
                            <Row>
                            {!configure && !safieLoading && !isViewPage &&
                            <>
                             <MessageContaier>
                                    <MessageLabel style={{'text-size-adjust': '100%'}}>
                                    Safie Connector is not configured yet.<br/>
                                    Please configure it from Settings &#62; Safie Connector
                                    </MessageLabel>
                                </MessageContaier>
                                <Button primary style={{'marginLeft': '5px'}} onClick={() => history.push('/safie-camera-settings')}>Go to Settings</Button>
                                </>}
                            </Row>
                            }
                            </>}

                        { source === "RTMP" &&
                            <Row>
                                <fieldset>
                                <legend>
                                RTMP
                                </legend>
                                <div style={{"padding": "5px"}}>
                                <div>
                                <input type="radio" id="rtmp_server_internal" name="rtmp_server_type" value="internal" onChange={handleRadioOnChange} checked={inputs.rtmp_server_type === "internal" || false} disabled={readOnly}></input> <label for="rtmp_server_internal"> Internal Server</label>
                                <input type="radio" id="rtmp_server_external" name="rtmp_server_type" value="external" onChange={handleRadioOnChange} checked={inputs.rtmp_server_type === "external" || false} disabled={readOnly} style={{"marginLeft": "50px"}}></input> <label for="rtmp_server_external"> External Server</label>
                                </div>

                                {inputs.rtmp_server_type === "internal" &&
                                <div style={{"marginTop": "20px"}}>
                                    <MandatoryLabelStream style={{"marginRight":"20px"}}>Server URL</MandatoryLabelStream>
                                    <RightDiv>
                                    {inputs.rtmp_url_type === "mDNS" &&
                                        <Button primary name="rtmp_url_type" className={"small-button"} onClick={() => handleOnLabelChange("IP")}>{inputs.rtmp_url_type}</Button>
                                    }
                                    {inputs.rtmp_url_type === "IP" &&
                                        <Button primary name="rtmp_url_type" className={"small-button"} onClick={() => handleOnLabelChange("mDNS")}>{inputs.rtmp_url_type}</Button>
                                    }
                                    { props.readOnly &&
                                        <Button primary onClick={clickToCopy} className={"small-button"}>Copy</Button>
                                    }
                                    </RightDiv>
                                    <TextField name="rtmp_source_url" type="text" value={
                                        inputs.rtmp_url_type === "mDNS" ? Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", mDnsName) : Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", deviceIP)} onChange={handleOnChange} maxLength="255" readOnly/>
                                </div>
                                }
                                {inputs.rtmp_server_type === "external" &&
                                <div style={{"marginTop": "20px"}}>
                                    <MandatoryLabelStream>Server URL</MandatoryLabelStream>
                                    <TextField name="rtmp_source_url" type="text" value={inputs.rtmp_source_url} onChange={handleOnChange} disabled={readOnly} maxLength="255"/>
                                </div>
                                }
                                <div style={{"marginTop": "20px"}}>
                                <Label>Stream Name/Key</Label>
                                { props.readOnly &&
                                    <RightDiv>
                                        <Button primary onClick={clickToCopyRTMPStreamKey} className={"small-button"}>Copy</Button>
                                    </RightDiv>
                                }
                                <SelectField name="rtmp_stream_key_type" value={inputs.rtmp_stream_key_type} onChange={handleRTMPStreamKeyChange} disabled={readOnly}>
                                        <option value="default_rtmp_stream_key">Same as Stream Name</option>
                                        <option value="custom_rtmp_stream_key">Custom</option>
                                    </SelectField>
                                {inputs.rtmp_stream_key_type === "default_rtmp_stream_key" ?
                                <TextField id="rtmp_stream_key" name="rtmp_stream_key" style={{ "marginTop": "10px"}} type="text" value={inputs.rtmp_stream_key_type === "default_rtmp_stream_key" ?  stackName : inputs.rtmp_stream_key} onChange={handleRTMPStreamKeyChange} readOnly maxLength="100" placeholder=""/>
                                :
                                <TextField id="rtmp_stream_key" name="rtmp_stream_key" style={{ "marginTop": "10px"}} type="text" value={inputs.rtmp_stream_key_type === "default_rtmp_stream_key" ?  stackName : inputs.rtmp_stream_key} onChange={handleRTMPStreamKeyChange} readOnly={inputs.rtmp_stream_key_type === "default_rtmp_stream_key" || readOnly} maxLength="100" placeholder="Enter Stream Key/Name"/>
                                }
                                <MandatoryLabelStream style={{"padding": "20px 0px 0px 0px"}}>Buffer Time</MandatoryLabelStream>
                                <TextField style={{'margin':'-5px 0 5px 0'}} name="rtmp_source_buffer_time" type="text" value={inputs.rtmp_source_buffer_time} onChange={handleNumberOnChange} disabled={readOnly} maxLength="4"/>
                                <Label style={{"padding": "10px 0px 0px 0px"}}>Framerate of Analysis</Label>
                                <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.VIDEO_FPS_LIST}/>
                                </SelectField>
                                {/* <Label style={{"padding": "20px 0px 0px 0px"}}>Frame Time</Label>
                                <SelectField name="frame_time" value={inputs.frame_time} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.FRAME_TIME}/>
                                </SelectField> */}
                                </div>
                                </div>
                                </fieldset>
                            </Row>}

                            { source === "RTSP" &&
                            <Row>
                                <div>
                                <MandatoryLabelStream>RTSP URL</MandatoryLabelStream>
                                <TextField name="rtsp_source_url" type="text" value={inputs.rtsp_source_url} onChange={handleOnChange} disabled={readOnly} maxLength="255" placeholder="e.g. rtsp://192.168.100.1/ or rtspt://192.168.100.1/"/>
                                <Label style={{"padding": "20px 0px 0px 0px"}}>Framerate of Analysis</Label>
                                <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.VIDEO_FPS_LIST}/>
                                </SelectField>
                                {/* <Label style={{"padding": "20px 0px 0px 0px"}}>Frame Time</Label>
                                <SelectField name="frame_time" value={inputs.frame_time} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.FRAME_TIME}/>
                                </SelectField> */}
                                <MandatoryLabelStream style={{"padding": "20px 0px 0px 0px"}}>Buffer Time</MandatoryLabelStream>
                                <TextField name="rtsp_source_buffer_time" type="text" value={inputs.rtsp_source_buffer_time} onChange={handleNumberOnChange} disabled={readOnly} maxLength="4"/>
                                
                                 <Row>
                                <div>
                                <MandatoryLabelStream style={{"padding": "20px 0px 0px 0px"}}>Maximum Packet Delay (Secs)</MandatoryLabelStream>
                        
                                <SelectField name="maximum_packet_delay_type" value={inputs.maximum_packet_delay_type} onChange={handleSourcePacketDelay} disabled={readOnly}>
                                    <option value="auto">auto</option>
                                    <option value="custom">Custom</option>
                                </SelectField>
                                { inputs.maximum_packet_delay_type === "custom" &&
                                    <TextField id="maximum_packet_delay" name="maximum_packet_delay" style={{ "marginTop": "10px"}} maxLength="4" type="text" value={inputs?.maximum_packet_delay} onChange={handleSourcePacketDelay} readOnly={readOnly} />
                                }
                                </div>
                                </Row>                             
                                
                                <Label style={{"padding": "20px 0px 0px 0px"}}>Short Header</Label>
                                <SelectField name="rtsp_source_short_header" value={inputs.rtsp_source_short_header} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.RTSP_SOURCE_SHORT_HEADERS}/>
                                </SelectField>
                                <Label style={{"padding": "20px 0px 0px 0px"}}>User Name</Label>
                                <TextField name="rtsp_source_user" type="text" value={inputs.rtsp_source_user} onChange={handleOnChange} disabled={readOnly} maxLength="100"/>
                                <Label style={{"padding": "20px 0px 0px 0px"}}>Password</Label>
                                <PasswordField id="rtsp_source_pass" name="rtsp_source_pass" value={inputs.rtsp_source_pass} setInput={handleOnChange} width="" readOnly={readOnly} maxLength="100" />
                                </div>
                            </Row>}
                            { source === "HTTP" &&
                            <Row>
                                <div>
                                <MandatoryLabelStream>HTTP URL</MandatoryLabelStream>
                                <TextField name="http_source_url" type="text" value={inputs.http_source_url} onChange={handleOnChange} disabled={readOnly} maxLength="255" placeholder="e.g. http://192.168.100.1/ or https://192.168.100.1/"/>
                                <Label style={{"padding": "20px 0px 0px 0px"}}>Framerate of Analysis</Label>
                                <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.VIDEO_FPS_LIST}/>
                                </SelectField>
                                </div>
                            </Row>}

                            { source === "USB" && videoSourceType &&
                            <Row>
                             <fieldset>
                             <legend>
                                Video Source
                                </legend>
                                <div style={{'marginTop': '10px'}}>
                                <Label>Resolution</Label>
                                <SelectField name="resolution" value={inputs.resolution} onChange={videoInfoOnChange} disabled={isViewPage ? readOnly : noVideoDevice ? true : false}>
                                    <SelectOptions items={resolutions}/>
                                </SelectField>
                                <Label style={{"padding": "20px 0px 0px 0px"}}>Frame Rate</Label>
                                <SelectField name="video_fps" value={inputs.video_fps} onChange={videoInfoOnChange} disabled={isViewPage ? readOnly : noVideoDevice ? true : false}>
                                    <SelectOptions items={frameRates}/>
                                </SelectField>
                                {/* <Label style={{"padding": "20px 0px 0px 0px"}}>Frame Time</Label>
                                <SelectField name="frame_time" value={inputs.frame_time} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.FRAME_TIME}/>
                                </SelectField> */}
                                <Label style={{"padding": "20px 0px 0px 0px"}}>Video Flipping</Label>
                                <SelectField name="video_flip_method" value={inputs.video_flip_method} onChange={handleOnChange} disabled={isViewPage ? readOnly : noVideoDevice ? true : false} style={{ 'marginBottom': '10px' }}>
                                    <SelectOptions items={Constants.VIDEO_FLIP_METHODS}/>
                                </SelectField>
                                </div>
                             </fieldset>
                            </Row>}
                        
                          { source === "RTP" &&
                            <Row style={{'marginTop': '20px'}}>
                            <fieldset style={{"margin-top": "15px"}}>
                                <legend>Video</legend>
                                { inputs.transport_encapsulation !== "H264" &&
                                <InnerContainer>
                                    <Label style={{"padding": "10px 0px 0px 0px"}}>Codec</Label>
                                    <SelectField3 name="video_codec" value={inputs.video_codec} onChange={handleOnChange} disabled={readOnly}>
                                        { inputs.transport_encapsulation !== "MPEG2PS" ? 
                                            <SelectOptions items={Constants.VIDEO_CODEC_TYPE} /> : 
                                            <SelectOptions items={Constants.VIDEO_CODEC_TYPE_MPEG2PS} />}
                                    </SelectField3>
                                </InnerContainer>
                                }
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>RTP Payload Type</ResponsivTextLabel>
                                    <TextField name="video_payload_type" type="text" value={inputs.video_payload_type} onChange={handleOnChange} disabled={readOnly} maxLength="10"/>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'center'}}>
                                    <ResponsivTextLabel>Multicast {inputs.enable_video_multicast ? "Enabled" : "Disabled"}</ResponsivTextLabel>
                                    <SelectionControl id="enable_video_multicast" style={{"marginTop":"3px", "display": "inline-block", "height": "25px"}} type="switch" name="enable_video_multicast" labelBefore="true" checked={inputs.enable_video_multicast || false} onChange={handleToggleChange}/>
                                </InnerContainer>
                                { inputs.enable_video_multicast &&
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>Multicast Address</TextFieldMandatoryField>
                                    <TextField name="video_multicast_address" type="text" value={inputs.video_multicast_address} onChange={handleIpAddrChange} disabled={readOnly} maxLength="100"/>
                                </InnerContainer>
                                }
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>RTP Port</TextFieldMandatoryField>
                                    <TextField name="video_port_rtp" type="text" value={inputs.video_port_rtp} onChange={handleNumberOnChange} onBlur={incrementRTCPort} disabled={readOnly} maxLength="5"/>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'center'}}>
                                    <ResponsivTextLabel>RTCP Muxed</ResponsivTextLabel>
                                    <SelectionControl id="enable_video_rtcp_muxed" style={{"marginTop":"3px", "display": "inline-block", "height": "25px"}} type="switch" name="enable_video_rtcp_muxed" labelBefore="true" checked={inputs.enable_video_rtcp_muxed || false} onChange={handleToggleChange}/>
                                </InnerContainer>
                                { inputs.enable_video_rtcp_muxed === false &&
                                <div style={{"display": "flex", "flex-direction": "column"}}>
            
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>RTCP Port</TextFieldMandatoryField>
                                    <TextField name="video_port_rtcp" type="text" value={inputs.video_port_rtcp} onChange={handleNumberOnChange} disabled={readOnly} maxLength="5"/>
                                </InnerContainer>
                                </div>
                                }
                                {(inputs.transport_encapsulation === "MPEG2TS" ||  inputs.transport_encapsulation === "MPEG2TTS") && 
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>Interlace Mode</ResponsivTextLabel>
                                    <SelectField name="video_interlace_mode" value={inputs.video_interlace_mode} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.INTERLACE_MODES}/>
                                    </SelectField>
                                </InnerContainer>}
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>Framerate of Analysis</ResponsivTextLabel>
                                    <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.VIDEO_FPS_LIST}/>
                                    </SelectField>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>Pixel Aspect Ratio (PAR)</ResponsivTextLabel>
                                    <SelectField name="pixel_aspect_ratio" value={inputs.pixel_aspect_ratio} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.PIXEL_ASPECT_RATIO}/>
                                    </SelectField>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>Maximum Packet Delay <br /> (Secs)</TextFieldMandatoryField>
                                    <TextField name="maximum_packet_delay" type="text" value={inputs.maximum_packet_delay} maxLength="4" onChange={handlePacketDelay} disabled={readOnly} />
                                </InnerContainer>
                                {/* <InnerContainer>
                                    <ResponsivTextLabel>Frame Time</ResponsivTextLabel>
                                    <SelectField name="frame_time" style={{"margin-left": "101px"}} value={inputs.frame_time} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.FRAME_TIME}/>
                                    </SelectField>
                                </InnerContainer> */}
                                
                            </fieldset>
                            <InnerContainer>
                            <TextFieldLabelAudio>{inputs.enable_audio === true ? "Audio Enabled" : "Audio Disabled"}</TextFieldLabelAudio>
                            <SelectionControl id="enable_audio" type="switch" name="enable_audio" style={{"marginTop":"3px","margin-left": "26px", "display": "inline-block", "height": "25px"}} labelBefore="false" checked={inputs.enable_audio || false} onChange={handleToggleChange}/>
                            </InnerContainer>
                            { inputs.enable_audio &&
                            <fieldset>
                                <legend>Audio</legend>
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>Type</ResponsivTextLabel>
                                    <SelectField name="rtp_audio_source_type" value={inputs.rtp_audio_source_type} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.AUDIO_SOURCE_TYPE}/>
                                    </SelectField>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>RTP Payload Type</ResponsivTextLabel>
                                    <TextField name="audio_payload_type" type="text" value={inputs.audio_payload_type} onChange={handleOnChange} disabled={readOnly} maxLength="10"/>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'center'}}>
                                    <ResponsivTextLabel>Multicast {inputs.enable_audio_multicast ? "Enabled" : "Disabled"}</ResponsivTextLabel>
                                    <SelectionControl id="enable_audio_multicast" style={{"marginTop":"3px", "display": "inline-block", "height": "25px"}} type="switch" name="enable_audio_multicast" labelBefore="true" checked={inputs.enable_audio_multicast || false} onChange={handleToggleChange}/>
                                </InnerContainer>
                                { inputs.enable_audio_multicast &&
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>Multicast Address</TextFieldMandatoryField>
                                    <TextField name="audio_multicast_address" type="text" value={inputs.audio_multicast_address} onChange={handleIpAddrChange} disabled={readOnly} maxLength="100"/>
                                </InnerContainer>}
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>RTP Port</TextFieldMandatoryField>
                                    <TextField name="audio_port_rtp" type="text" value={inputs.audio_port_rtp} onChange={handleNumberOnChange} onBlur={incrementRTCPort} disabled={readOnly} maxLength="5"/>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'center'}}>
                                    <ResponsivTextLabel>RTCP Muxed</ResponsivTextLabel>
                                    <SelectionControl id="enable_audio_rtcp_muxed" style={{"marginTop":"3px", "display": "inline-block",  "height": "25px"}} type="switch" name="enable_audio_rtcp_muxed" labelBefore="true" checked={inputs.enable_audio_rtcp_muxed || false} onChange={handleToggleChange}/>
                                </InnerContainer>
                                    { inputs.enable_audio_rtcp_muxed === false &&
                                    <div style={{"display": "flex", "flex-direction": "column"}}>                
                                    <InnerContainer style={{alignItems: 'baseline'}}>
                                        <TextFieldMandatoryField>RTCP Port</TextFieldMandatoryField>
                                        <TextField name="audio_port_rtcp" type="text" value={inputs.audio_port_rtcp} onChange={handleNumberOnChange} disabled={readOnly} maxLength="5"/>
                                    </InnerContainer>
                                    </div>}
                            </fieldset>}
                            </Row>}
                            { source === "MLIT" &&
                            <Row style={{'marginTop': '20px'}}>
                            <fieldset style={{"margin-top": "15px"}}>
                                <legend>Video</legend>
                                { inputs.enable_video_multicast &&
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>Multicast Address</TextFieldMandatoryField>
                                    <TextField name="video_multicast_address" type="text" value={inputs.video_multicast_address} onChange={handleIpAddrChange} disabled={readOnly} maxLength="100"/>
                                </InnerContainer>
                                }
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>RTP Port</TextFieldMandatoryField>
                                    <TextField name="video_port_rtp" type="text" value={inputs.video_port_rtp} onChange={handleNumberOnChange} onBlur={incrementRTCPort} disabled={readOnly} maxLength="5"/>
                                </InnerContainer>
                                <InnerContainer style={{alignItems: 'center'}}>
                                    <ResponsivTextLabel>RTCP Muxed</ResponsivTextLabel>
                                    <SelectionControl id="enable_video_rtcp_muxed" style={{"marginTop":"3px", "display": "inline-block", "height": "25px"}} type="switch" name="enable_video_rtcp_muxed" labelBefore="true" checked={inputs.enable_video_rtcp_muxed || false} onChange={handleToggleChange}/>
                                </InnerContainer>
                                { inputs.enable_video_rtcp_muxed === false &&
                                <div style={{"display": "flex", "flex-direction": "column"}}>
            
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>RTCP Port</TextFieldMandatoryField>
                                    <TextField name="video_port_rtcp" type="text" value={inputs.video_port_rtcp} onChange={handleNumberOnChange} disabled={readOnly} maxLength="5"/>
                                </InnerContainer>
                                </div>
                                }
                                {(inputs.transport_encapsulation === "MPEG2TS" ||  inputs.transport_encapsulation === "MPEG2TTS") && 
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>Interlace Mode</ResponsivTextLabel>
                                    <SelectField name="video_interlace_mode" value={inputs.video_interlace_mode} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.INTERLACE_MODES}/>
                                    </SelectField>
                                </InnerContainer>}
                                {((inputs.video_interlace_mode === 'progressive') && (inputs.transport_encapsulation === "MPEG2TS" ||  inputs.transport_encapsulation === "MPEG2TTS")) &&
                                    <InnerContainer style={{alignItems: 'center'}}>
                                    <ResponsivTextLabel>Multicast to RTSP</ResponsivTextLabel>
                                    <SelectionControl id="RTP_TS_PROG_DECODE" style={{"marginTop":"3px", "display": "inline-block", "height": "25px"}} type="switch" name="RTP_TS_PROG_DECODE" labelBefore="true" checked={inputs.RTP_TS_PROG_DECODE || false} onChange={handleToggleChange}/>
                                </InnerContainer>}
                                {inputs.transport_encapsulation !== 'MPEG2PS' &&
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>Framerate of Analysis</ResponsivTextLabel>
                                    <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.VIDEO_FPS_LIST}/>
                                    </SelectField>
                                </InnerContainer>}
                                {inputs.transport_encapsulation === 'MPEG2PS' &&
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <ResponsivTextLabel>Framerate of Analysis</ResponsivTextLabel>
                                    <SelectField name="video_fps" value={inputs.video_fps} onChange={handleOnChange} disabled={readOnly}>
                                        <SelectOptions items={Constants.VIDEO_FPS_LIST}/>
                                    </SelectField>
                                </InnerContainer>}
                                <InnerContainer style={{alignItems: 'baseline'}}>
                                    <TextFieldMandatoryField>Maximum Packet Delay <br /> (Secs)</TextFieldMandatoryField>
                                    <TextField name="maximum_packet_delay" type="text" value={inputs.maximum_packet_delay} maxLength="4" onChange={handlePacketDelay} disabled={readOnly} />
                                </InnerContainer>                  
                            </fieldset>
                            </Row>}

                        </ResponsiveColumn>}
                        {source === "SAFIE" && inputs.capture_type === 'still_capture' && configure && !isViewPage &&
                            <ResponsiveColumn>
                                <FieldSetRecording style={{"marginTop":"15px", "marginBottom":"10px"}}>
                                    <legend>Periodic Snapshot</legend>
                                    <div style={{"display": "flex", "flex-direction": "column"}}>
                                        <div style={{'display': 'flex', 'marginBottom': '5px'}}>
                                        <ResponsiveSelectionControl id="periodic_snapshot" type="switch" label="Save" name="periodic_snapshot" labelBefore="true" checked={inputs.periodic_snapshot || false} onChange={handleToggleChange} disabled={!cameraOptions} />
                                        <SelectionControl id="enable_snapshot_time_overlay" type="switch" label="Time Overlay" name="enable_snapshot_time_overlay" labelBefore="true" checked={inputs.enable_snapshot_time_overlay} onChange={handleToggleChange} disabled={!cameraOptions}/>
                                        </div>
                                    
                                        <div style={{"display": "flex"}}>
                                            <Label style={{"marginTop":"10px", "width": "255px"}}>Save Interval (Secs)</Label>
                                            <TextField name="save_interval" type="text" value={inputs.save_interval} pattern="[0-9]*" onChange={handleNumberOnChange} disabled={readOnly ? readOnly || inputs.periodic_snapshot === false : !cameraOptions || inputs.periodic_snapshot === false } maxLength="4"/>
                                        </div>
                                    </div>
                                </FieldSetRecording>
                            </ResponsiveColumn>}

                        {source === "SAFIE" && inputs.capture_type === 'still_capture' && configure && isViewPage && cameraOptions &&
                            <ResponsiveColumn>
                                <FieldSetRecording style={{"marginTop":"15px", "marginBottom":"10px"}}>
                                    <legend>Periodic Snapshot</legend>
                                    <div style={{"display": "flex", "flex-direction": "column"}}>
                                        <div style={{'display': 'flex', 'marginBottom': '5px'}}>
                                        <ResponsiveSelectionControl id="periodic_snapshot" type="switch" label="Save" name="periodic_snapshot" labelBefore="true" checked={inputs.periodic_snapshot || false} onChange={handleToggleChange} disabled={!cameraOptions} />
                                        <SelectionControl id="enable_snapshot_time_overlay" type="switch" label="Time Overlay" name="enable_snapshot_time_overlay" labelBefore="true" checked={inputs.enable_snapshot_time_overlay} onChange={handleToggleChange} disabled={!cameraOptions}/>
                                        </div>
                                    
                                        <div style={{"display": "flex"}}>
                                            <Label style={{"marginTop":"10px", "width": "255px"}}>Save Interval (Secs)</Label>
                                            <TextField name="save_interval" type="text" value={inputs.save_interval} pattern="[0-9]*" onChange={handleNumberOnChange} disabled={readOnly ? readOnly || inputs.periodic_snapshot === false : !cameraOptions || inputs.periodic_snapshot === false } maxLength="4"/>
                                        </div>
                                    </div>
                                </FieldSetRecording>
                            </ResponsiveColumn>}

                            {source === "SAFIE" && inputs.capture_type === 'still_capture' && !configure && isViewPage && !cameraOptions &&
                            <ResponsiveColumn>
                                <FieldSetRecording style={{"marginTop":"15px", "marginBottom":"10px"}}>
                                    <legend>Periodic Snapshot</legend>
                                    <div style={{"display": "flex", "flex-direction": "column"}}>
                                        <div style={{'display': 'flex', 'marginBottom': '5px'}}>
                                        <ResponsiveSelectionControl id="periodic_snapshot" type="switch" label="Save" name="periodic_snapshot" labelBefore="true" checked={inputs.periodic_snapshot || false} onChange={handleToggleChange} disabled={!cameraOptions} />
                                        <SelectionControl id="enable_snapshot_time_overlay" type="switch" label="Time Overlay" name="enable_snapshot_time_overlay" labelBefore="true" checked={inputs.enable_snapshot_time_overlay} onChange={handleToggleChange} disabled={!cameraOptions}/>
                                        </div>
                                    
                                        <div style={{"display": "flex"}}>
                                            <Label style={{"marginTop":"10px", "width": "255px"}}>Save Interval (Secs)</Label>
                                            <TextField name="save_interval" type="text" value={inputs.save_interval} pattern="[0-9]*" onChange={handleNumberOnChange} disabled={readOnly ? readOnly || inputs.periodic_snapshot === false : !cameraOptions || inputs.periodic_snapshot === false } maxLength="4"/>
                                        </div>
                                    </div>
                                </FieldSetRecording>
                            </ResponsiveColumn>}

                        {(source === "RTP" || source === "USB" || source === "RTMP" || source === "RTSP" || source === "HTTP" || source === "MLIT" || (source=== 'SAFIE' && inputs.capture_type === 'live_streaming') ) &&
                        <ResponsiveColumn>
                            <div>
                            <fieldset>
                            <legend>Recording in MP4</legend>
                            <div style={{"marginTop":"5px", "display": "flex", "flex-direction": "column"}}>
                                {modalOpen && <ResponsivePopupContainer>
                                    <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                                        <Mp4Popup closePopup={closePopup} mp4Details={mp4Details} source={source} inputs={inputs} />
                                    </Popup>
                                </ResponsivePopupContainer>}
                                <div style={{'display': 'flex', 'marginBottom': '5px', width:'100%', justifyContent:'space-between'}}>
                                    <div style={{'display': 'flex'}}>
                                        <ResponsiveSelectionControl id="enable_save_mp4" type="switch" label="Save" name="enable_save_mp4" labelBefore="true" style={{"flex-shrink": "0"}} checked={inputs.enable_save_mp4 || false} onChange={handleToggleChange} />
                                        {(source === "USB" &&
                                        (inputs.video_source_type == "UVC_H264"
                                            ? 
                                            <SelectionControl id="enable_mp4_time_overlay" type="switch" label="Time Overlay" name="enable_mp4_time_overlay" labelBefore="true" checked={false} disabled={true}/>
                                            :
                                            <SelectionControl id="enable_mp4_time_overlay"  type="switch" label="Time Overlay" name="enable_mp4_time_overlay" labelBefore="true" checked={inputs.enable_mp4_time_overlay || false} onChange={handleToggleChange} />
                                        )
                                        )}
                                        {(source === "RTP" &&
                                        ((inputs.transport_encapsulation === "H264") || (inputs.transport_encapsulation === "MPEG2TS" && inputs.video_codec === 'H264') || (inputs.transport_encapsulation === "MPEG2TTS" && inputs.video_codec === 'H264') || (inputs.transport_encapsulation === "MPEG2PS" && inputs.video_codec === 'H264')
                                            ? 
                                            <SelectionControl id="enable_mp4_time_overlay" type="switch" label="Time Overlay" name="enable_mp4_time_overlay" labelBefore="true" checked={false} disabled={true}/>
                                            :
                                            <SelectionControl id="enable_mp4_time_overlay"  type="switch" label="Time Overlay" name="enable_mp4_time_overlay" labelBefore="true" checked={inputs.enable_mp4_time_overlay || false}  onChange={handleToggleChange} />
                                        )
                                        )}
                                        {(source === "MLIT" &&
                                        ( (inputs.transport_encapsulation === "MPEG2PS" ) && 
                                            <SelectionControl id="enable_mp4_time_overlay"  type="switch" label="Time Overlay" name="enable_mp4_time_overlay" labelBefore="true" checked={inputs.enable_mp4_time_overlay || false}  onChange={handleToggleChange} />
                                        )
                                        )}
                                        {(source === "RTMP") &&
                                            <SelectionControl id="enable_mp4_audio_save" type="switch" label="Audio Enabled" name="enable_mp4_audio_save" labelBefore="true" checked={inputs.enable_mp4_audio_save} onChange={handleToggleChange} />
                                        }
                                    </div>
                                    {
                                        source==='USB' && 
                                        <>{mp4Details && <div style={{"display": "flex"}}><ButtonStyled onClick={getMp4Details}>Show MP4 Details</ButtonStyled></div>}</> 
                                    }
                                
                                </div>
                                <div style={{"margin-bottom": "15px", "margin-top": "5px"}}>
                                <ResponsiveLabel>Split By</ResponsiveLabel>
                                <input type="radio" id="video_split_mode_time" name="video_split_mode" value="time" onChange={handleOnChange} checked={inputs.video_split_mode === "time" || false} disabled={isViewPage ? readOnly || inputs.enable_save_mp4 === false : noVideoDevice ? true : false || inputs.enable_save_mp4 === false} style={{ 'cursor': 'pointer' }}></input>
                                <Label style={{'width': '50px', 'cursor': 'pointer'}} for="video_split_mode_time"> Time</Label>
                                <input type="radio" id="video_split_mode_size" name="video_split_mode" value="size" onChange={handleOnChange} checked={inputs.video_split_mode === "size" || false} disabled={isViewPage ? readOnly || inputs.enable_save_mp4 === false : noVideoDevice ? true : false || inputs.enable_save_mp4 === false} style={{ 'cursor': 'pointer' }}></input>
                                <Label style={{'cursor': 'pointer'}} for="video_split_mode_size"> Size</Label>
                                </div>
                                {inputs.video_split_mode === "time" &&
                                  <div style={{"display": "flex", 'marginBottom': '20px'}}>
                                      <Label style={{"marginTop":"7px", "width": "255px"}}>Interval (Secs)</Label>
                                      <TextField name="video_duration" type="text" value={inputs.video_duration} onChange={handleNumberOnChange} disabled={isViewPage ? readOnly || inputs.enable_save_mp4 === false : noVideoDevice ? true : false || inputs.enable_save_mp4 === false} maxLength="4" />
                                  </div>
                                }
                                {inputs.video_split_mode === "size" &&
                                  <div style={{"display": "flex", 'marginBottom': ((source === "RTSP" || (source === "RTP" && inputs.transport_encapsulation === "H264")) ? '20px' : '20px')}}>
                                      <Label style={{"marginTop":"7px", "width": "255px"}}>Interval (MiB)</Label>
                                      <TextField name="video_file_max_size" type="text" value={inputs.video_file_max_size} onChange={handleNumberOnChange} disabled={isViewPage ? readOnly || inputs.enable_save_mp4 === false : noVideoDevice ? true : false || inputs.enable_save_mp4 === false} />
                                  </div>
                                }

                                { source === "USB" &&
                                <>
                                    <div style={{"display": "flex", 'marginBottom': '20px'}}>
                                        <Label style={{"marginTop":"7px", "width": "255px"}}>Video Quality</Label>
                                        <SelectField name="recording_quality" value={inputs.recording_quality} onChange={handleOnChange} disabled={isViewPage ? readOnly || inputs.enable_save_mp4 === false : noVideoDevice ? true : false || inputs.enable_save_mp4 === false}>
                                            <SelectOptions items={Constants.VIDEO_QUALITIES}/>
                                        </SelectField>
                                    </div>
                                    {(inputs.video_source_type === 'UVC_I420' || inputs.video_source_type === 'UVC_MJPG') && 
                                    <div style={{"display": "flex", 'marginBottom': '10px'}}>
                                        <Label style={{"width": "255px"}}>H264 Profile</Label>
                                        <SelectField name="h264_profile" value={inputs.h264_profile} onChange={videoInfoOnChange} disabled={isViewPage ? readOnly || inputs.enable_save_mp4 === false : noVideoDevice ? true : false || inputs.enable_save_mp4 === false}>
                                            <SelectOptions items={Constants.H264_PROFILE}/>
                                        </SelectField>
                                    </div>}
                                </>}
                                {(source === 'RTMP') &&
                                    <div style={{"display": "flex", 'marginBottom': '10px'}}>
                                        <Label style={{"marginTop":"7px", "width": "255px"}}>H264 Profile</Label>
                                        <SelectField  name="h264_profile" value={inputs.h264_profile} onChange={videoInfoOnChange} disabled={readOnly || inputs.enable_save_mp4 === false}>
                                            <SelectOptions items={Constants.H264_PROFILE}/>
                                        </SelectField>
                                    </div> 
                                }
                                {/* {(source === 'RTSP') &&
                                    <div style={{"display": "flex", 'marginBottom': '10px'}}>
                                        <Label style={{"marginTop":"7px", "width": "255px"}}>H264 Profile</Label>
                                        <SelectField  name="h264_profile" value={inputs.h264_profile} onChange={videoInfoOnChange} disabled={readOnly || inputs.enable_save_mp4 === false}>
                                            <SelectOptions items={Constants.H264_PROFILE}/>
                                        </SelectField>
                                    </div> 
                                } */}
                                {(source === 'RTP' && inputs.transport_encapsulation === 'MPEG2PS') &&
                                        <div style={{"display": "flex", 'marginBottom': '10px'}}>
                                            <Label style={{"marginTop":"7px", "width": "255px"}}>H264 Profile</Label>
                                            <SelectField  name="h264_profile" value={inputs.h264_profile} onChange={videoInfoOnChange} disabled={readOnly || inputs.enable_save_mp4 === false}>
                                                <SelectOptions items={Constants.H264_PROFILE}/>
                                            </SelectField>
                                </div>}
                                
                            </div>
                            </fieldset>
                            <fieldset style={{"marginTop":"15px", "marginBottom":"10px"}}>
                            <legend>Periodic Snapshot</legend>
                            <div style={{"display": "flex", "flex-direction": "column"}}>
                                <div style={{'display': 'flex', 'marginBottom': '5px'}}>
                                <ResponsiveSelectionControl id="periodic_snapshot" type="switch" label="Save" name="periodic_snapshot" labelBefore="true" style={{"flex-shrink": "0"}} checked={inputs.periodic_snapshot || false} onChange={handleToggleChange} />
                                <SelectionControl id="enable_snapshot_time_overlay" type="switch" label="Time Overlay" name="enable_snapshot_time_overlay" labelBefore="true" checked={inputs.enable_snapshot_time_overlay || false} onChange={handleToggleChange} />
                                </div>
                               
                                <div style={{"display": "flex"}}>
                                    <Label style={{"marginTop":"10px", "width": "255px"}}>Save Interval (Secs)</Label>
                                    <TextField name="save_interval" type="text" value={inputs.save_interval} pattern="[0-9]*" onChange={handleNumberOnChange} disabled={isViewPage ? readOnly || inputs.periodic_snapshot === false : noVideoDevice ? true : false || inputs.periodic_snapshot === false} maxLength="4" />
                                </div>
                                <div style={{"display": "flex", "margin-top": "10px"}}>
                                <div style={{"margin-top": "15px", "width": "255px"}}>
                                <div style={{display:'flex'}}>
                                <input type="checkbox" onChange={handleCheckboxOnChange} checked={inputs.specify_snapshot_resolution} disabled={!inputs.periodic_snapshot || isViewPage ? readOnly || inputs.periodic_snapshot === false : noVideoDevice ? true : false || inputs.periodic_snapshot === false} name="specify_snapshot_resolution" />&nbsp;<SnapShotDiv1 style={{fontSize:'15.4px', color: getColor() }}>Specify Resolution</SnapShotDiv1>
                                </div>
                                </div>
                                <SelectField id="periodic_snapshot_resolution" name="periodic_snapshot_resolution" style={{ "margin-top": "10px" }} value={inputs.periodic_snapshot_resolution} onChange={handleOnChange} disabled={ (inputs.specify_snapshot_resolution === true ? readOnly==true : readOnly ==false || inputs.periodic_snapshot === false || inputs.specify_snapshot_resolution === false) || (inputs.periodic_snapshot === true ? readOnly==true : readOnly ==false  || inputs.periodic_snapshot === false || inputs.specify_snapshot_resolution === false) }>
                                        <SelectOptions items={Constants.SNAPSHOT_RESOLUTIONS}/>
                                    </SelectField>
                                </div>
                                 <ResolutionDiv style={{display:'flex'}}>                                                                                                                       
                                 <input type="checkbox" disabled={!inputs.periodic_snapshot || (inputs.specify_snapshot_resolution ? (readOnly ? true: false) : true)} checked={inputs.save_original_snapshot} name="save_original_snapshot" onChange={handleCheckboxOnChange} />&nbsp; <SnapShotDiv style={{fontSize:'15.4px', color: getColor() }}>Save the snapshots of the original size</SnapShotDiv>
                                </ResolutionDiv>
                            </div>
                           
                            </fieldset>
                            </div>
                        </ResponsiveColumn>
                        }
                    </ResponsiveRow>
                    <ResponsiveRow>
                        { source === "FTP" &&
                            <ResponsiveColumn>
                                <div>
                                <Label>Source Type</Label>
                                <SelectField name="image_source_type" value={inputs.image_source_type} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.IMAGE_SOURCE_TYPE}/>
                                </SelectField>
                                <div style={{"padding": "10px 0px 0px 0px"}}>
                                <fieldset style={{"padding-bottom": "15px"}}>
                                <legend>
                                FTP
                                </legend>
                                <div style={{"display": "none"}}>
                                <input type="radio" id="server_internal" name="server_type" value="internal" onChange={handleRadioOnChange} checked={inputs.server_type === "internal" || false} disabled={readOnly}></input> <label for="server_internal"> Internal Server</label>
                                <input type="radio" id="server_external" name="server_type" value="external" onChange={handleRadioOnChange} checked={inputs.server_type === "external" || false} disabled={readOnly} style={{"marginLeft": "50px"}}></input> <label for="server_external"> External Server</label>
                                </div>
                                {inputs.server_type === "internal" &&
                                <div style={{"marginTop": "20px"}}>
                                    <Label style={{"marginRight":"20px"}}>Server</Label>
                                    <RightDiv>
                                    {inputs.url_type === "mDNS" &&
                                        <Button primary name="url_type" className={"small-button"} onClick={() => handleOnSrcDirLabelChange("IP")}>{inputs.url_type}</Button>
                                    }
                                    {inputs.url_type === "IP" &&
                                        <Button primary name="url_type" className={"small-button"} onClick={() => handleOnSrcDirLabelChange("mDNS")}>{inputs.url_type}</Button>
                                    }
                                    { props.readOnly &&
                                        <Button primary  onClick={clickToCopyFtpUrl} className={"small-button"}>Copy</Button>
                                    }
                                    </RightDiv>
                                    <TextField name="server_url" type="text" value={
                                        inputs.url_type === "mDNS" ? Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", mDnsName) : Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", deviceIP)} onChange={handleOnChange} maxLength="255" readOnly/>
                                </div>
                                }
                                {inputs.server_type === "external" &&
                                <div style={{"marginTop": "20px"}}>
                                    <Label>Server</Label>
                                    <TextField name="source_url" type="text" value={inputs.source_url} onChange={handleOnChange} disabled={readOnly} maxLength="255"/>
                                </div>
                                }
                                <Row>
                                <Column style={{"padding": "10px 0%"}}>
                                <div style={{"display": "flex"}}>
                                    <Label style={{"padding": "10px 5px 0px 0px","display": "flex"}}>Port</Label>
                                    <PortTextField name="port" type="text" value={inputs.server_type === "internal" ? Constants.FTP_SERVER_PORT :inputs.port} onChange={handleNumberOnChange} disabled={readOnly || inputs.server_type === "internal"} maxLength="5"/>
                                    </div>
                                 </Column>
                                <Column>
                                <div>
                                {inputs.server_type === "external" &&<SelectionControl id="enable_passive_mode" type="switch" label="Passive Mode" name="enable_passive_mode" labelBefore="true" checked={inputs.enable_passive_mode || false} onChange={handleToggleChange}/>}
                                </div>
                                </Column>
                                </Row>
                                <Row>
                                <div>
                                <Label>Directory To Fetch Images</Label>
                                { props.readOnly &&
                                    <RightDiv>
                                        <Button primary onClick={clickToCopySourceDir} className={"small-button"}>Copy</Button>
                                    </RightDiv>
                                }
                                <SelectField name="source_directory_type" value={inputs.source_directory_type} onChange={handleSourceDirChange} disabled={readOnly}>
                                    <option value="default_source_directory">Same as Stream Name</option>
                                    <option value="custom_source_directory">Custom</option>
                                </SelectField>
                                { inputs.source_directory_type === "default_source_directory" ?
                                <TextField id="source_directory" name="source_directory" style={{ "marginTop": "10px"}} type="text" value={"/image_streams/" + stackName} onChange={handleSourceDirChange} readOnly maxLength="100" placeholder=""/>
                                :
                                <div style={{position: "relative"}}>
                                    <span className="ftp-fixed-input">/image_streams/</span>
                                    <TextField1 id="source_directory" name="source_directory" style={{ "marginTop": "10px"}} type="text" value={inputs.source_directory} onChange={handleSourceDirChange} readOnly={readOnly} maxLength="100"/>
                                </div>
                                }
                                </div>
                                </Row>
                                <div>
                                    <Label style={{"padding": "10px 0px 0px 0px"}}>File Extension</Label>
                                <SelectField name="file_extension" value={inputs.file_extension} onChange={handleOnChange} disabled={readOnly}>
                                    <SelectOptions items={Constants.IMAGE_FILE_EXTENSIONS}/>
                                </SelectField>
                                </div>
                                </fieldset>
                                </div>
                               </div>
                            </ResponsiveColumn>
                        }

                        {source === "FTP" &&
                        <ResponsiveColumn>
                            <div>
                            {/* <fieldset style={{"marginTop":"15px", "marginBottom":"10px"}}>
                            <legend>Periodic Snapshot</legend>
                            <div style={{"display": "none"}}>
                                <SelectionControl id="periodic_snapshot" style={{"width": "200px"}} type="switch" label="Save" name="periodic_snapshot" labelBefore="true" checked={inputs.periodic_snapshot || false} onChange={handleToggleChange}/>
                                <Label style={{"marginTop":"10px", "marginRight":"10px"}}>Save Interval (Secs)</Label>
                                <TextField style={{"width": "60px"}} name="save_interval" type="text" value={inputs.save_interval} pattern="[0-9]*" onChange={handleNumberOnChange} disabled={readOnly || inputs.periodic_snapshot === false} maxLength="4"/>
                            </div>
                            <SelectionControl id="enable_snapshot_time_overlay" style={{"marginTop":"5px"}} type="switch" label="Time Overlay" name="enable_snapshot_time_overlay" labelBefore="true" checked={inputs.enable_snapshot_time_overlay || false} onChange={handleToggleChange}/>
                            </fieldset> */}
                            <FieldSetTime>
                            <legend>Ingest Time</legend>
                            <div style={{"marginTop":"5px", "display": window.innerWidth <= 1024 ?"table" : "flex"}}>
                            <div>
                            <Label style={{"display":"block"}}>Based On</Label>
                                <div style={{"display": "flex", "align-items": "center"}}>
                                    <input type="radio" id="based_on_clock" name="ingest_time" value="System Clock" onChange={handleRadioOnChange} checked={inputs.ingest_time === "System Clock" || false} disabled={readOnly}></input> <label for="based_on_clock">System Clock</label>
                                    <input type="radio" id="based_on_name" name="ingest_time" value="File Name" onChange={handleRadioOnChange} checked={inputs.ingest_time === "File Name" || false} disabled={readOnly} style={{"marginLeft": "15px"}}></input> <label for="based_on_name">File Name</label>
                                </div>
                            </div>
                            {inputs.ingest_time === "File Name" && 
                            <div style={{"paddingLeft": "10px"}}>
                                <div style={{"display":"flex", "alignItems": "baseline", "paddingTop": window.innerWidth <= 1024 ?"10px" : "0px"}}>
                                <Label>Date Time Format</Label>
                                <InfoIcon title={Constants.DATE_TIME_FORMAT_EXAMPLE}/>
                                </div>
                                <TextField name="date_time_format" type="text" value={inputs.date_time_format} onChange={handleOnChange} onBlur={handleDateOnBlur} disabled={readOnly} maxLength="50" size="24"/>
                            </div>}
                            </div>
                            </FieldSetTime>
                            <fieldset style={{"padding": "10px 15px 20px 15px"}}>
                            <legend>
                                Login Details
                            </legend>
                            <SelectionControl id="enable_user_login" type="switch" label="Enable User Login" name="enable_user_login" labelBefore="true" checked={inputs.enable_user_login || false} onChange={handleToggleChange}/>
                            <div style={{display:"flex",paddingTop:"20px"}}>
                                <LoginMandatoryFieldLabel>Username</LoginMandatoryFieldLabel>
                                <TextField id="username" name="username" value={inputs.username} maxLength="100" style={{flexGrow: '1',  maxWidth:"250px"}} onChange={handleOnChange} disabled={!inputs.enable_user_login || readOnly}/>
                            </div>
                            <div style={{display:"flex", paddingTop:"20px"}}>
                                <LoginMandatoryFieldLabel>Password</LoginMandatoryFieldLabel>
                                <div style={{flexGrow: '1', maxWidth: '250px'}}>
                                    <PasswordField id="password" name="password" value={inputs.password} setInput={handleOnChange} width="100%" readOnly={!inputs.enable_user_login || readOnly} />
                                </div>
                            </div>
                            <SelectionControl id="enable_anonymous_access" type="switch" label="Allow Anonymous Access" name="enable_anonymous_access" labelBefore="true" style={{paddingTop:"23px"}} checked={inputs.enable_anonymous_access || false} onChange={handleToggleChange}/>
                        </fieldset>
                            </div>
                            <fieldset style={{"marginTop":"15px", "marginBottom":"10px"}}>
                            <legend>Periodic Snapshot</legend>
                            <div style={{"display": "flex", "flex-direction": "column"}}>
                                <div style={{'display': 'flex', 'marginBottom': '5px'}}>
                                <ResponsiveSelectionControl id="periodic_snapshot" type="switch" label="Save" name="periodic_snapshot" labelBefore="true" checked={inputs.periodic_snapshot || false} onChange={handleToggleChange}/>
                                <SelectionControl id="enable_snapshot_time_overlay" type="switch" label="Time Overlay" name="enable_snapshot_time_overlay" labelBefore="true" checked={inputs.enable_snapshot_time_overlay || false} onChange={handleToggleChange}/>
                                </div>
                               
                                <div style={{"display": "flex"}}>
                                    <Label style={{"marginTop":"10px", "width": "255px"}}>Save Interval (Secs)</Label>
                                    <TextField name="save_interval" type="text" value={inputs.save_interval} pattern="[0-9]*" onChange={handleNumberOnChange} disabled={readOnly || inputs.periodic_snapshot === false} maxLength="4"/>
                                </div>
                            </div>
                           
                            </fieldset>
                        </ResponsiveColumn>}
                    </ResponsiveRow>
                      <Row>
                      {isViewPage && 
                          <CenterDiv>
                              { readOnly === true &&
                                  <Button id="editRecorderButton" primary onClick={editRecorder}>Edit</Button>
                              }
                              { readOnly === false &&
                                  <div>
                                  <Button id="saveRecorderButton" primary onClick={saveRecorder}>Save</Button>
                                  <Button id="cancelRecorderButton" onClick={cancelRecorder}>Cancel</Button>
                                  </div>
                              }
                          </CenterDiv>
                      }
                      </Row>
                </div>
            }
            </TabPanel>
            )
    }

const Mp4Popup = (props) => {
    const { closePopup, mp4Details, source, inputs } = props;
    return(
        <div> 
            <PopupContainer>
                <SectionHeader style={{ 'display': 'flex' }}>
                    <SectionLabel>MP4 Properties</SectionLabel>
                </SectionHeader>
                {mp4Details === 'notFound' ? 
                    <div style={{ 'max-height': '200px', 'padding': '15px 20px 20px 20px' }}>
                        <Row>
                            <CenterDiv>
                                <Label>No details found.</Label>
                            </CenterDiv>
                        </Row>
                    </div> :
                        <>
                            <Tabs defaultTab="general" style={{ 'padding': '0px 6px' }}>
                                <TabList>
                                    <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
                                    {(((source === 'RTP') && (inputs.transport_encapsulation === 'MPEG2PS' || (inputs.transport_encapsulation === 'MPEG2TS' && inputs.video_interlace_mode === 'mixed') || (inputs.transport_encapsulation === 'MPEG2TTS' && inputs.video_interlace_mode === 'mixed'))) || (source === 'RTMP') || ((source === 'USB') && ( inputs.video_source_type === 'UVC_I420' ||  inputs.video_source_type === 'UVC_MJPG'))) && <Tab tabFor="advanced" className={'rwt__tab'}>Advanced</Tab> }
                                </TabList>
                                <TabPanel tabId="general">
                                <div style={{'padding': '5px 20px 20px 20px' }}>
                                    <Row>
                                        <ColumnGenralComp style={{'width':'40%'}}>Bitrate (kbps)</ColumnGenralComp>
                                        <ColumnGenralComp>{mp4Details.bitrate === '' ? source === 'RTSP' || source === "HTTP" ? 'NA' : '-' : mp4Details.bitrate}</ColumnGenralComp>
                                    </Row>
                                    <Row>
                                        <ColumnGenralComp style={{'width':'40%','margin':'-10px 0px 0px 0px'}}>Interlace Mode</ColumnGenralComp>
                                        <ColumnGenralComp style={{'width':'60%','margin':'-10px 0px 0px 0px'}}>{mp4Details.interlace_mode === '' ? (source === 'RTSP' || source === "HTTP" || (source === 'RTP' && (inputs.transport_encapsulation === 'MPEG2TTS' || inputs.transport_encapsulation === 'MPEG2TS') && inputs.video_interlace_mode === 'progressive')) ? 'NA' : 'NA' : mp4Details.interlace_mode}</ColumnGenralComp>
                                    </Row>
                                    <Row>
                                        <ColumnGenralComp style={{'width':'40%','margin':'-10px 0px 0px 0px'}}>Intra Frames (GOP)</ColumnGenralComp>
                                        <ColumnGenralComp style={{'width':'60%','margin':'-10px 0px 0px 0px'}}>{mp4Details.gop === '' ? (source === 'RTSP' || source === "HTTP" || (source === 'RTP' && (inputs.transport_encapsulation === 'MPEG2TTS' || inputs.transport_encapsulation === 'MPEG2TS') && inputs.video_interlace_mode === 'progressive')) ? 'NA' : 'NA' : mp4Details.gop }</ColumnGenralComp>
                                    </Row>
                                    <Row>
                                        <ColumnGenralComp style={{'width':'40%','margin':'-10px 0px 0px 0px'}}>Video Codec</ColumnGenralComp>
                                        <ColumnGenralComp style={{'width':'60%','margin':'-10px 0px 0px 0px'}}>{mp4Details.video_codec === '' ? (source === 'RTP' && (inputs.transport_encapsulation === 'MPEG2TTS' || inputs.transport_encapsulation === 'MPEG2TS') && inputs.video_interlace_mode === 'progressive') ? 'NA' : 'NA' : mp4Details.video_codec }</ColumnGenralComp>
                                    </Row>
                                    {(source === 'RTP' || source === 'RTSP' || source === "HTTP") && 
                                        <Row>
                                            <Column style={{'width':'50%'}}>Height</Column>
                                            <Column style={{'width':'50%'}}>{mp4Details.height === '' ? 'NA' : mp4Details.height }</Column>
                                        </Row>}
                                    {(source === 'RTP' || source === 'RTSP' || source === "HTTP") && 
                                        <Row>
                                            <Column style={{'width':'50%'}}>Width</Column>
                                            <Column style={{'width':'50%'}}>{mp4Details.width === '' ? 'NA' : mp4Details.width }</Column>
                                        </Row>}
                                </div>
                                </TabPanel>
                                <TabPanel tabId="advanced" style={{'max-height': '300px', 'min-height': '200px','overflow-y':'auto'}}>
                                {mp4Details && Object.keys(mp4Details.advanced).length === 0 ?
                                    <div style={{ 'max-height': '200px', 'min-height': '200px', 'padding': '15px 20px 20px 20px' }}>
                                        <Row>
                                        <CenterDiv>
                                            <Label>No details found.</Label>
                                        </CenterDiv>
                                        </Row>
                                    </div> : 
                                    <div>
                                    {Object.entries(mp4Details.advanced).map(([key,value])=>{
                                        return(
                                            <div style={{'padding': '5px 20px 20px 20px' }}>
                                                <Row>
                                                    <Column1 style={{'width':'70%'}}>{key}</Column1>
                                                    <Column1 style={{'width':'30%'}} id={key}>{value}</Column1>
                                                </Row>
                                            </div>
                                        )})}
                                    </div>}
                                </TabPanel>
                            </Tabs>
                            <Row>
                                <PopupButtons>
                                <Button primary onClick={() => { closePopup(); }}>OK</Button>
                                </PopupButtons>
                            </Row>
                    </>}
                
            </PopupContainer>
        </div>
    )
}

export default RecordingTab;
